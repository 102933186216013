import React, {ReactNode, DragEvent} from 'react';
import styled from 'styled-components';
import {Rnd} from 'react-rnd';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {DivContainer, DeleteIcon} from './styled';

interface ResizableDivContainerProps {
    div: {
        width?: number;
        height?: number;
        x: number;
        y: number;
    };
    onResize?: (size: { width: number; height: number }) => void;
    onDrag?: (position: { x: number; y: number }) => void;
    onDelete?: () => void;
    onDrop?: (e: DragEvent<HTMLDivElement>) => void;
    children: ReactNode;
}

const ResizableDivContainer: React.FC<ResizableDivContainerProps> = ({
      div,
      onResize,
      onDrag,
      onDelete,
      onDrop,
      children,
      }) => {

    return (
        <Rnd
            bounds="parent"
            style={{overflow: 'hidden', flexWrap: 'wrap'}}
            size={{width: div.width || 100, height: div.height || 40}}
            position={{x: div.x, y: div.y}}
            disableDragging={!onDrag}
            onResize={(e, direction, ref, delta, position) => {
                if(onResize) onResize({width: ref.offsetWidth, height: ref.offsetHeight});
            }}
            onDrag={(e, d) => {
              if (onDrag) onDrag({x: d.x, y: d.y})
            }}
        >
            <DivContainer 
                onDrop={(e) => onDrop(e)} 
                onDragOver={(e) => e.preventDefault()} 
                data-div={div}
                // draggable={false}
                style={{width: '100%', height: '100%', padding: 5, flexWrap: 'wrap'}}
            >
                {children}
                {
                    !!onDelete && 
                    <DeleteIcon onClick={onDelete}>
                        <AiOutlineCloseCircle/>
                    </DeleteIcon>
                }
            </DivContainer>
        </Rnd>
    );
};

export default ResizableDivContainer;
