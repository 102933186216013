import React, { useState } from "react";
import { QuestionContent } from "./styled";
import {
  BlockTitle,
  FullWidthContainer,
  LeftContent,
  RightContent,
} from "components/styled";
import ButtonLink from "components/ButtonLink";
import { useDispatch } from "react-redux";
import { setTypeQuestionOpenInEditMode } from "../../../redux/slices/EditModeSlice";
import { useNavigate } from "react-router-dom";
import { setClosePreview } from "redux/slices/PreviewSlice";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import QuestiontypeModal from "components/QuestiontypeModal";

interface PreviewWrittenRecordedMathEssayRTProps {
  currentQuestion: {
    id: number;
    question: string;
    more_options: {
      metadata: {
        distractor_rationale: string;
        rubric_reference: string;
        sample_answer: string;
        acknowledgements: string;
        stimulus_review: string;
        instructor_stimulus: string;
      };
      validation: {
        max_score: string;
        min_score_if_attempted: number;
      };
      ui_style: {
        fontsize: string;
        min_height: string;
        max_height: string;
        disable_auto_link: string;
        placeholder: string;
        submit_over_limit: boolean;
        spellcheck: boolean;
        is_math?: boolean;
      };
    };
    options: {
      text_blocks: [];
      content: string;
      formatting_options: [];
      max_length: number;
      show_word_limit: string;
      show_word_count: boolean;
    };
    type: string;
  };
  showAnswer?: any;
  setShowAnswers?: any;
  parentMode?: string;
  editMode?: boolean;
}

const PreviewWrittenRecordedMathEssayRT: React.FC<
  PreviewWrittenRecordedMathEssayRTProps
> = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = "assessment",
  editMode = false,
}) => {
    const [content, setContent] = useState<string>(
      currentQuestion?.options?.content || ""
    );
    const [isOverLimit, setIsOverLimit] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const maxWords = currentQuestion?.options?.max_length || 0;
    const [isModalOpen, setIsModalOpen] = useState(false);
    const minHeight = currentQuestion?.more_options?.ui_style?.min_height || 100;
    const maxHeight = currentQuestion?.more_options?.ui_style?.max_height || 100;

    const handleEditorBlur = (newContent: string) => {
      const text = newContent.trim();
      const words = text.split(/\s+/).filter((word) => word !== "");

      if (words.length > maxWords) {
        setIsOverLimit(true);
      } else {
        setIsOverLimit(false);
      }
      setContent(newContent);
    };

    const handleEditClick = () => {
      dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
      navigate(`/edit-subquestion/${currentQuestion.id}`);
    };
    const placeholder = currentQuestion?.more_options?.ui_style?.placeholder;
    const instructorStimulus =
      currentQuestion?.more_options?.metadata?.instructor_stimulus;

    const config = {
      // readonly: false,
      // toolbarAdaptive: true,
      placeholder: placeholder,
      toolbar: { items: currentQuestion?.options?.formatting_options },
    };

    const fontSize = currentQuestion?.more_options?.ui_style?.fontsize;
    const fontSizeMapping = {
      small: "12px",
      medium: "14px",
      large: "17px",
      "x-large": "20px",
      "xx-large": "24px",
    };
    const mappedFontSize = fontSizeMapping[fontSize] || "14px";

    const wordCount =
      content.trim() === "<p><br></p>"
        ? 0
        : content
          .trim()
          .split(/\s+/)
          .filter((word) => word !== "").length;

    return (
      <QuestionContent
        parentMode={parentMode === "question"}
        style={{ fontSize: mappedFontSize }}
      >
        <FullWidthContainer>
          <LeftContent>
            {instructorStimulus !== "" && !editMode && (
              <BlockTitle>{instructorStimulus}</BlockTitle>
            )}
            <BlockTitle
              dangerouslySetInnerHTML={{
                __html: `Question: ${currentQuestion?.question}`,
              }}
            />
          </LeftContent>
          <RightContent>
            {editMode ? (
              <>
                <ButtonLink
                  margin
                  title={"Go to edit"}
                  onClick={() => handleEditClick()}
                />
                <ButtonLink
                  margin
                  title={"Delete"}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                  }}
                />

                {isModalOpen && (
                  <QuestiontypeModal
                    // @ts-ignore
                    setIsModalOpen={setIsModalOpen}
                    id={currentQuestion?.id as any}
                    isModalOpen={isModalOpen}
                  />
                )}
              </>
            ) : (
              <>
                <button
                  type="button"
                  onClick={() => {
                    dispatch(setClosePreview());
                  }}
                  style={{
                    width: "150px",
                    height: "40px",
                    fontWeight: 600,
                    color: "#FFF",
                    borderRadius: "8px",
                    backgroundColor: "#FA9B31",
                  }}
                >
                  Back to edit
                </button>
              </>
            )}
          </RightContent>
        </FullWidthContainer>
        <div>
          <div className={isOverLimit ? "editor-over-limit" : "editor"}>
            <CKEditor
              editor={ClassicEditor}
              onReady={(editor) => {
                editor.ui.view.editable.element.style.minHeight = `${minHeight}px`;
                editor.ui.view.editable.element.style.maxHeight = `${maxHeight}px`;
              }}
              data={content}
              config={config}
              disabled={true}
              onChange={(event, editor) => {
                const data = editor.getData();
                handleEditorBlur(data);
              }}
            />
          </div>
          <div
            className="flex gap-1 mt-2"
            style={isOverLimit ? { color: "red" } : {}}
          >
            {currentQuestion?.options?.show_word_limit === "off" ? (
              ""
            ) : (
              <p>Word Limit: {maxWords}</p>
            )}
            {currentQuestion?.options?.show_word_count && (
              <p>
                {currentQuestion?.options?.show_word_limit === "off"
                  ? "Word(s) "
                  : "/ "}
                {wordCount}
              </p>
            )}
          </div>
        </div>
      </QuestionContent>
    );
  };

export default PreviewWrittenRecordedMathEssayRT;
