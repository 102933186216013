import React, { useEffect, useRef } from 'react';
import DragContainer from 'components/DragContainer/DragContainer';
import DOMPurify from 'dompurify';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { TOptionItem } from 'types';

type ShowOrderListAnswersProps = {
  orderList: TOptionItem[];
  correctAnswers: TOptionItem[];
  dragItem: any;
  dragOverItem: any;
  handleSort: () => void;
};

export const ShowOrderListAnswers = ({
  orderList,
  correctAnswers,
  dragItem,
  dragOverItem,
  handleSort,
}: ShowOrderListAnswersProps) => {
  const textRef = useRef<Array<HTMLDivElement | null>>([]);
  const correctTextRef = useRef<Array<HTMLSpanElement | null>>([]);

  useEffect(() => {
    orderList?.forEach((item, i) => {
      if (textRef.current[i]) {
        textRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
      }
    });
  }, [orderList]);

  const more_options = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["ordlis"] as any)?.more_options
  );

  useEffect(() => {
    correctAnswers?.forEach((item, i) => {
      if (correctTextRef.current[i]) {
        correctTextRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
      }
    });
  }, [correctAnswers]);

  const getLabel = (index: number) => {
    let label = "";
    if (more_options?.ui_style?.validation_stem_numeration) {
      switch (more_options.ui_style.validation_stem_numeration) {
        case 'numerical':
          label = (index + 1).toString();
          break;
        case 'lower-alpha':
          label = String.fromCharCode(97 + index);
          break;
        case 'upper-alpha':
          label = String.fromCharCode(97 + index).toUpperCase();
          break;
        default:
          break;
      }
    }
    return label;
  };

  const list_style = more_options?.ui_style?.list_style || 'default';

  const renderItems = () => {
    switch (list_style) {
      case 'inline':
        return (
          <div>
            <div className="inline-flex gap-2">
              {orderList?.map((item, i) => (
                <div key={i} className="flex items-center gap-2">
                  <DragContainer
                    index={i}
                    key={i}
                    onDragEnd={handleSort}
                    dragItem={dragItem}
                    dragOverItem={dragOverItem}
                  >
                    <div ref={(el) => (textRef.current[i] = el)} />
                  </DragContainer>
                </div>
              ))}
            </div>
            <div className="w-full mt-2 flex flex-wrap gap-2">
              {correctAnswers?.map((item, i) => (
                <div key={i} className="flex items-center gap-2">
                  <div
                    className={`${
                      correctAnswers && correctAnswers[i]?.label === item?.label
                        ? 'border-2 p-1 border-green-500'
                        : 'border-2 p-1 border-red-500'
                    }`}
                  >
                    <span style={{ marginRight: '1px' }}>{getLabel(i)}.</span>
                    <span ref={(el) => (correctTextRef.current[i] = el)} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        );
        case 'list':
          return (
            <div  className="flex flex-col gap-2">
              {orderList?.map((item, i) => (
                <div key={i} className="flex items-center gap-2" >
                  <DragContainer
                    index={i}
                    key={i}
                    onDragEnd={handleSort}
                    dragItem={dragItem}
                    dragOverItem={dragOverItem}

                  >
                    <div ref={(el) => (textRef.current[i] = el)} className="p-2" style={{ border: 'none', boxShadow: 'none' }} />
                  </DragContainer>
                </div>
              ))}
              <div className="w-full mt-2 flex flex-wrap gap-2">
                {correctAnswers?.map((item, i) => (
                  <div key={i} className="flex items-center gap-2">
                    <div
                      className={`${
                        correctAnswers && correctAnswers[i]?.label === item?.label
                          ? 'border-2 p-1 border-green-500'
                          : 'border-2 p-1 border-red-500'
                      } p-2`}
                      style={{ border: 'none', boxShadow: 'none' }}
                    >
                      <span style={{ marginRight: '1px' }}>{getLabel(i)}.</span>
                      <span ref={(el) => (correctTextRef.current[i] = el)} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          );
      default:
        return orderList?.map((item, i) => (
          <div key={i} className="flex items-center gap-2">
            <DragContainer
              index={i}
              key={i}
              onDragEnd={handleSort}
              dragItem={dragItem}
              dragOverItem={dragOverItem}
            >
              <div ref={(el) => (textRef.current[i] = el)} />
            </DragContainer>
            <div
              className={`${
                correctAnswers && correctAnswers[i]?.label === item?.label
                  ? 'border-2 p-1 border-green-500'
                  : 'border-2 p-1 border-red-500'
              }`}
            >
              <span style={{ marginRight: '1px' }}>{getLabel(i)}.</span>
              <span ref={(el) => (correctTextRef.current[i] = el)} />
            </div>
          </div>
        ));
    }
  };

  return <>{renderItems()}</>;
};
