import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { hasAuthentication, reloadAuthentication } from './CheckAuthentication';

import { RootState } from '../redux/store';
import { setIsLogged, setIsLogout } from '../redux/slices/AuthenticationSlice';

let authenticationReloaded = false;

type Authentication = {
  id: number;
  lastRequestAt?: number;
  token: string;
  rememberMe: boolean;
};

export const Authentication = ({ children }: any) => {
  const dispatch = useDispatch();
  const authentication = useSelector(
    (state: RootState) => state.authentication
  );

  const [isLoading, setIsLoading] = useState(true);

  if (authentication.isLogged && !hasAuthentication()) {
    dispatch(setIsLogout());
  }

  if (authentication.isLogged && hasAuthentication()) {
    reloadAuthentication();
  }

  if (
    (authentication.isLogged && hasAuthentication()) ||
    !hasAuthentication() ||
    !isLoading
  )
    return children;

  const reloadUser = async () => {
    reloadAuthentication();
    dispatch(setIsLogged());
    setIsLoading(false);
  };

  if (!authenticationReloaded) {
    authenticationReloaded = true;
    reloadUser();
  }

  return null;
};
