import React, { useEffect, useState } from 'react';

import { setClosePreview } from '../../../redux/slices/PreviewSlice';

import { useAppDispatch } from 'hooks/useAppDispatch';
import { MatchListBlock } from 'components/subquestions/MatchListBlock/MatchListBlock';
import { setEmptyResponses } from 'pages/AuthorQuestion/ClassifyMatch/utils';

import PreviewWrapper from '../PreviewWrapper/PreviewWrapper';
import { useNavigate } from 'react-router';
import { formatDataWithLabel } from 'utils';
import { setTypeQuestionOpenInEditMode } from 'redux/slices/EditModeSlice';

const PreviewMatchList = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode,
  editMode,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [subQuestion, setSubQuestion] = useState(null);
  const [correctChoices, setCorrectChoices] = useState([]);
  const [correctAnswers, setCorrectAnswers] = useState(null);

  const [stimulus, setStimulus] = useState(null);
  const [possibleChoices, setPossibleChoices] = useState([]);

  const [localShowAnswer, setLocalShowAnswer] = useState(false);

  /* 
    Set data with the correct responses according to the current tab type (valid or alternate)
  */
  const handleDrop = (index: number, word: string) => {
    let resposesList = [...correctChoices];
    resposesList[index] = word;

    setCorrectChoices((prev) => (prev = resposesList));
  };

  useEffect(() => {
    setSubQuestion((prev) => (prev = currentQuestion?.correct_answer));

    setStimulus(
      (prev) =>
        (prev = formatDataWithLabel(
          currentQuestion?.correct_answer?.stimulus_list
        ))
    );

    setPossibleChoices(
    /*  (prev) =>
        (prev = formatDataWithLabel(
          currentQuestion?.correct_answer?.valid_response?.value
        ))*/

          (prev) =>
            (prev = formatDataWithLabel(
              currentQuestion?.options?.possible_responses?.value
            ))
    );

    setCorrectAnswers(
      (prev) => (prev = currentQuestion?.correct_answer?.valid_response?.value)
    );
  }, [currentQuestion]);

  useEffect(() => {
    stimulus && setCorrectChoices(setEmptyResponses(stimulus));
  }, [stimulus]);

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const handleDataRest = () => {
    setCorrectChoices(setEmptyResponses(stimulus));
  };

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer ?? localShowAnswer}
      setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
    >
      {subQuestion && (
        <MatchListBlock
          stimulus={stimulus}
          possibRes={possibleChoices}
          currentCorrectChoices={correctChoices}
          handleDrop={handleDrop}
          score={currentQuestion?.correct_answer?.valid_response?.score}
          showAnswer={showAnswer ?? localShowAnswer}
          correctAnswers={correctAnswers}
          editMode={false}
          possibOptions={possibleChoices}
          getId={currentQuestion?.id}
          // handleDataRest={handleDataRest}
        />
      )}
    </PreviewWrapper>
  );
};

export default PreviewMatchList;

PreviewMatchList.defaultProps = {
  setShowAnswers: null,
  parentMode: 'assessment',
  editMode: false,
};
