import React, { useEffect, useState } from "react";
import { QuestionContent } from "./styled";
import {
  BlockTitle,
  FullWidthContainer,
  LeftContent,
  RightContent,
} from "components/styled";
import ButtonLink from "components/ButtonLink";
import { useDispatch } from "react-redux";
import { setTypeQuestionOpenInEditMode } from "../../../redux/slices/EditModeSlice";
import { useNavigate } from "react-router-dom";
import { setClosePreview } from "redux/slices/PreviewSlice";
import QuestiontypeModal from "components/QuestiontypeModal";
import { FaCheck, FaTimes } from "react-icons/fa";

interface Response {
  score: number;
  value: string;
  matching_rule: string;
}
interface PreviewShortTextrops {
  currentQuestion: {
    id: number;
    question: string;
    correct_answer: {
      valid_response: Response;
      alt_response: Response[];
    };
    type: string;
    more_options: {
      metadata: {
        distractor_rationale: string;
        rubric_reference: string;
        sample_answer: string;
        acknowledgements: string;
        stimulus_review: string;
        instructor_stimulus: string;
      };
      ui_style: {
        fontsize: string;
        placeholder: string;
        spellcheck: boolean;
        is_math: boolean;
        input_type: string;
      };
      validation: {
        max_score: string;
        min_score_if_attempted: number;
      };
      feedback_attempts: number;
      max_length: number;
      case_sensitive: boolean;
      spellcheck: boolean;
      aria_label: string;
      penalty: number;
      instant_feedback: boolean;
      ignore_leading_and_trailing_spaces: true;
      enable_fullwidth_scoring: boolean;
      character_map: any;
      automarkable: true;
    };
  };

  showAnswer?: any;
  setShowAnswers?: any;
  parentMode?: string;
  editMode?: boolean;
}

const PreviewShortText: React.FC<PreviewShortTextrops> = ({
  currentQuestion,
  parentMode = "assessment",
  editMode = false,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  console.log(currentQuestion?.more_options?.metadata, "Meta Data");
  const instructor_stimulus = currentQuestion?.more_options?.metadata?.instructor_stimulus || '';
  const {
    max_length,
    case_sensitive,
    instant_feedback,
    feedback_attempts,
    aria_label,
    spellcheck
  } = currentQuestion.more_options || {};
  const { placeholder, input_type, fontsize } =
    currentQuestion.more_options?.ui_style || {};
  const fontSizeMapping = {
    small: "12px",
    medium: "14px",
    large: "17px",
    "x-large": "20px",
    "xx-large": "24px",
  };
  const mappedFontSize = fontSizeMapping[fontsize] || "14px";

  const [showAnswer, setShowAnswer] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null);
  const [attempts, setAttempts] = useState(0);
  const [highestScore, setHighestScore] = useState<number>(0);
  const [getScore, setGetScore] = useState<number>(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [correctAnswer, setCorrectAnswer] = useState<string>("");
  const [Value, setValue] = useState<any>("");

  const handleEditClick = () => {
    dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const findScoreForValue = (
    value: string,
    case_sensitive: boolean
  ): number => {
    const transformedValue = case_sensitive ? value : value.toLowerCase();
    const { valid_response, alt_response } = currentQuestion.correct_answer;

    const transformedValidResponseValue = case_sensitive
      ? valid_response.value
      : valid_response.value.toLowerCase();

    if (
      (valid_response.matching_rule === "contains" &&
        transformedValue.includes(transformedValidResponseValue)) ||
      (valid_response.matching_rule === "exactMatch" &&
        transformedValidResponseValue === transformedValue)
    ) {
      return valid_response.score;
    }

    const altResponse = alt_response.find((response) => {
      const transformedAltResponseValue = case_sensitive
        ? response.value
        : response.value.toLowerCase();

      if (
        (response.matching_rule === "contains" &&
          transformedValue.includes(transformedAltResponseValue)) ||
        (response.matching_rule === "exactMatch" &&
          transformedAltResponseValue === transformedValue)
      ) {
        return true;
      }
      return false;
    });

    if (altResponse) {
      return altResponse.score;
    }

    return 0;
  };


  const checkAnswerButton = () => {
    if (attempts < feedback_attempts) {
      if (getScore === highestScore) {
        setIsCorrect(true);
      } else {
        setIsCorrect(false);
      }
      setAttempts((prev) => prev + 1);
    }
  };

  const handleChangeValues = (value: string) => {
    setValue(value);
    setIsCorrect(null);
    setGetScore(findScoreForValue(value, case_sensitive));
  };

  useEffect(() => {
    let maxScore = Math.max(
      currentQuestion.correct_answer.valid_response.score,
      ...currentQuestion.correct_answer.alt_response.map(
        (response) => response.score
      )
    );
    setHighestScore(maxScore);
    const correctTab = currentQuestion.correct_answer.valid_response;
    if (maxScore === correctTab.score) {
      setCorrectAnswer(correctTab.value);
    } else {
      const validResponse = currentQuestion.correct_answer.valid_response.value;
      if (validResponse) {
        setCorrectAnswer(validResponse);
      } else {
        setCorrectAnswer("");
      }
    }
  }, [currentQuestion]);

  return (
    <QuestionContent
      parentMode={parentMode === "question"}
      style={{ fontSize: mappedFontSize }}
    >
      <FullWidthContainer>
        <LeftContent>
          {instructor_stimulus !== "" && !editMode && (
            <BlockTitle>{instructor_stimulus}</BlockTitle>
          )}
          <BlockTitle
            dangerouslySetInnerHTML={{
              __html: `Question: ${currentQuestion?.question}`,
            }}
          />
        </LeftContent>
        <RightContent>
          {editMode ? (
            <>
              <ButtonLink
                margin
                title={"Go to edit"}
                onClick={() => handleEditClick()}
              />
              <ButtonLink
                margin
                title={"Delete"}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              />

              {isModalOpen && (
                <QuestiontypeModal
                  // @ts-ignore
                  setIsModalOpen={setIsModalOpen}
                  id={currentQuestion?.id as any}
                  isModalOpen={isModalOpen}
                />
              )}
            </>
          ) : (
            <div className="flex gap-5 items-center justify-center">
              {correctAnswer && (
                <>
                  <p
                    className={`${getScore === highestScore && "bg-[#65a00d] text-white"
                      } mt-2 gap-1 border rounded-full border-green-500 p-2`}
                  >
                    {getScore} / {highestScore}
                  </p>

                  <div className="flex items-center justify-end border border-gray-200 p-4">
                    <input
                      type="checkbox"
                      checked={showAnswer}
                      onChange={() => setShowAnswer(!showAnswer)}
                      className="mr-2"
                    />
                    <label>Show Answers</label>
                  </div>
                </>
              )}

              <button
                type="button"
                onClick={() => {
                  dispatch(setClosePreview());
                }}
                style={{
                  width: "150px",
                  height: "40px",
                  fontWeight: 600,
                  color: "#FFF",
                  borderRadius: "8px",
                  backgroundColor: "#FA9B31",
                }}
              >
                Back to edit
              </button>
            </div>
          )}
        </RightContent>
      </FullWidthContainer>
      <div className="flex justify-end">
        {isCorrect !== null && // Only display icon if isCorrect is not null
          (isCorrect ? <FaCheck color="green" /> : <FaTimes color="red" />)}
      </div>

      <div className="mt-2.5">
        <input
          type={input_type}
          maxLength={max_length || null}
          placeholder={placeholder}
          spellCheck={spellcheck}
          value={Value}
          style={{ fontSize: fontsize }}
          disabled={editMode}
          aria-label={aria_label}
          className={`block w-full h-8 rounded-md border-0 px-3.5 
            ${isCorrect === null
              ? ""
              : isCorrect
                ? "bg-green-300"
                : "bg-red-400"
            }
            text-gray-900 shadow-sm ring-1 ring-inset  placeholder:text-gray-400   sm:text-sm sm:leading-6`}
          onChange={(e) => handleChangeValues(e.target.value)}
        />
      </div>

      {showAnswer && correctAnswer && (
        <div className="bg-yellow-100 p-4 rounded-sm mt-6">
          <p className="text-lg font-semibold text-gray-900 dark:text-gray-200">
            Correct Answer:
          </p>
          <p className=" text-black dark:text-gray-300 max-w-fit bg-gray-200 mt-4 p-4">
            {correctAnswer}
          </p>
        </div>
      )}
      {instant_feedback && correctAnswer && (
        <div className="mt-6 flex justify-end">
          <button
            disabled={attempts > 0 && attempts >= feedback_attempts}
            onClick={checkAnswerButton}
            className={`text-right font-semibold py-2 px-4 border rounded transition duration-300 ${attempts > 0 && attempts >= feedback_attempts
                ? "bg-[#aaaaaa] text-[#717171] cursor-not-allowed "
                : "bg-transparent hover:bg-accent text-gray-700 hover:text-white border hover:border-transparent"
              }`}
          >
            Check Answer
          </button>
        </div>
      )}
    </QuestionContent>
  );
};

export default PreviewShortText;
