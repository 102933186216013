import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import PreviewWrapper from 'components/PreviewQuestions/PreviewWrapper/PreviewWrapper';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';

import { Option, OptionsList } from './styled';
import DOMPurify from 'dompurify';
import OptionsForMcq from './OptionsForMcq.component';

const PreviewBlockLayout = ({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) => {
  // const textRef = useRef<Array<HTMLDivElement | null>>([]);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [localShowAnswer, setLocalShowAnswer] = useState(false);

  const handleBackToEditClick = () => {
    dispatch(setClosePreview());
  };

  const handleGoToEditClick = () => {
    dispatch(
      setTypeQuestionOpenInEditMode({
        type: currentQuestion?.type,
        id: currentQuestion?.id,
      })
    );
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  // useEffect(() => {
  //   currentQuestion?.options?.map((item, i) => {
  //     // parse html string
  //     if (textRef.current[i]) {
  //       textRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
  //     }
  //     return textRef.current[i].innerHTML;
  //   });
  // }, [currentQuestion?.options]);

  return (
    <PreviewWrapper
      currentQuestion={currentQuestion}
      showAnswer={showAnswer ?? localShowAnswer}
      setShowAnswers={setShowAnswers ?? setLocalShowAnswer}
      parentMode={parentMode}
      editMode={editMode}
      handleGoToEdit={handleGoToEditClick}
      handleBackToEdit={handleBackToEditClick}
    >
      <OptionsForMcq 
        data = {currentQuestion}
        showAnswer={showAnswer || localShowAnswer}
        multiResponse={ currentQuestion?.settings && currentQuestion?.settings?.multiple_response ? currentQuestion?.settings?.multiple_response : false}
      />
      {/* <OptionsList>
        {Array.isArray(currentQuestion?.options) &&
          currentQuestion?.options?.map((option, index) => (
            <Option
              key={index}
              className={
                showAnswer || localShowAnswer
                  ? index === currentQuestion.correctIndex
                    ? 'correct'
                    : 'incorrect'
                  : ''
              }
              showAnswer={showAnswer ?? localShowAnswer}
              isCorrect={
                Array.isArray(
                  currentQuestion?.correct_answer['valid_response']?.value
                ) &&
                currentQuestion?.correct_answer['valid_response']?.value.some(
                  (item) => item?.id === option?.id
                )
              }
            >
              {/* {option?.label} */}
              {/*<div ref={(el) => (textRef.current[index] = el)} />
            </Option>
          ))}
      </OptionsList> */}
    </PreviewWrapper>
  );
};

export default PreviewBlockLayout;
