import React from 'react';
import { BsTrashFill } from 'react-icons/bs';
import { AiOutlineClose } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';

import {
  deleteSubQuestion,
  DeleteSubQuestionResponse,
} from '../api-rest/questions';
import { deleteSubQuestionById } from '../redux/slices/EditQuestionSlice';
import { toast } from 'react-toastify';

interface ModalProps {
  setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
  id?: string | string[]; // Optional
  isModalOpen: boolean;
  api?: string; // Optional
  method?: any; // Optional, adjust the type accordingly
  type?: string; // Optional
  multiple?: boolean;
}
// @ts-ignore
const QuestiontypeModal: React.FC<ModalProps> = ({
  setIsModalOpen,
  id,
  isModalOpen,
}) => {
  const dispatch = useDispatch();

  // Delete Question Type
  const deleteHandler = async (id) => {
    const { status }: DeleteSubQuestionResponse = await deleteSubQuestion(id);

    if (status === 200) {
      await dispatch(deleteSubQuestionById(id));
      toast.success('Sub question deleted successfully!');
      setIsModalOpen(false);

      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else toast.error('Something went wrong');
  };

  return (
    <Overlay isOpen={isModalOpen}>
      <div
        className="z-50 p-4 overflow-x-hidden "
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translate(-50%)',
          width: '400px',
        }}
      >
        <div className="relative w-full max-h-full shadow-xl">
          <div className="relative bg-white rounded-lg shadow ">
            <button
              type="button"
              className="absolute top-3 right-2.5 text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-hide="popup-modal"
              onClick={() => setIsModalOpen(false)}
            >
              <AiOutlineClose className="text-xl" />
              <span className="sr-only">Close modal</span>
            </button>

            <div className="p-6 text-center">
              <BsTrashFill className="mx-auto mb-4 text-[#dc3545] w-5 h-5" />
              <h3 className="mb-5 text-lg font-bold ">Are you sure ?</h3>

              <p className="mb-5 text-md font-normal">
                You want to delete this Question type.
              </p>

              <div className="flex justify-between">
                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="bg-light-accent text-accent border-accent border font-medium rounded-lg text-sm inline-flex items-center px-5 py-2.5 text-center mr-2"
                  onClick={() => deleteHandler(id)}
                >
                  Yes
                </button>

                <button
                  data-modal-hide="popup-modal"
                  type="button"
                  className="text-white bg-red-600 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 "
                  onClick={() => setIsModalOpen(false)}
                >
                  No
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Overlay>
  );
};

export const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'block' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
`;

export default QuestiontypeModal;
