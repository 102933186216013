import React, { forwardRef } from 'react';
import { Container, ErrorMessage, Input, Label, Note } from './styled';
import { Typography } from '@mui/material';

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  label?: string;
  value?: string;
  type?: string;
  error?: any;
  placeholder?: string;
  note?: string;
  disabled?: boolean;
  height?: number | string;
  width?: number | string;
  message?: any;
}

const InputWithLabel = forwardRef<HTMLInputElement, Props>(
  (
    {
      label,
      value,
      type = 'text',
      placeholder,
      disabled = false,
      note,
      error,
      width,
      height,
      message,
      ...props
    },
    ref
  ) => {
    return (
      <div style={{ width, height }}>
        <Label className="block text-xs font-medium mb-2">
          {label}
          {props.required && (
            <Typography
              className="text-main-red"
              variant="caption"
              display="inline-block"
            >
              &nbsp;*
            </Typography>
          )}
        </Label>
        <Input
          ref={ref}
          type={type}
          value={value}
          placeholder={placeholder}
          disabled={disabled}
          className="block w-full h-[41px] border border-[#ECECEC] text-sm rounded-[5px] leading-tight focus:outline-none focus:bg-white px-5"
          {...props}
        />
        {error && (
          <ErrorMessage className="mt-3 text-main-red">
            {error.message || `${label} is required.`}
          </ErrorMessage>
        )}
        {note && <Note>Note: {note}</Note>}
      </div>
    );
  }
);

export default InputWithLabel;
