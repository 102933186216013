import React, { Fragment, useEffect, useState } from 'react';
import { QuestionContent } from './styled';

import Button from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { Rnd } from 'react-rnd';
import { setClosePreview } from 'redux/slices/PreviewSlice';
import QuestiontypeModal from 'components/QuestiontypeModal';

export default function PreviewClozeImageMath({
  currentQuestion,
  showAnswer = undefined,
  setShowAnswers = undefined,
  parentMode = 'assessment',
  editMode = false,
}) {
  const [elements, setElements] = useState([]);
  const [localShowAnswer, setLocalShowAnswer] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validResponse = currentQuestion?.correct_answer?.valid_response;
  const more_options = currentQuestion?.more_options;

  useEffect(() => {
    let data =
      currentQuestion?.correct_answer &&
      currentQuestion?.correct_answer?.response_containers
        ? JSON.parse(
            JSON.stringify(currentQuestion.correct_answer?.response_containers)
          )
        : '';
    for (let i = 0; i < data.length; i++) {
      let inputString = data[i].template;
      if (inputString) {
        const parts = inputString.split('\\colorbox{#e4e4e4}{{Response}}}');
        let T = [];
        for (let i = 0; i < parts.length; i++) {
          if (parts[i] !== undefined && parts[i] !== '') {
            T.push({ type: 'txt', content: parts[i] });
            if (i != parts.length - 1)
              T.push({ type: 'Res', content: 'Response' });
          }
        }
        data[i].template = T;
      }
    }
    setElements(data);
  }, []);

  const handleEditClick = () => {
    dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  return (
    <QuestionContent parentMode={parentMode === 'question'}>
      <div className="flex flex-wrap gap-5 justify-between mb-5">
        <div className="w-full lg:w-[70%]">
          <div className="bg-light-accent flex p-5 gap-3 rounded-lg">
            <p className="font-bold text-sm">Question :&nbsp;</p>
            <span
              dangerouslySetInnerHTML={{
                __html: ` ${currentQuestion?.question}`,
              }}
            />
          </div>
        </div>

        <div className="flex gap-4">
          {editMode ? (
            <>
              <Button
                margin
                title={'Go to edit'}
                onClick={() => handleEditClick()}
              />

              <Button
                title={'Delete'}
                onClick={(e) => {
                  e.preventDefault();
                  setIsModalOpen(true);
                }}
              />

              {isModalOpen && (
                <QuestiontypeModal
                  // @ts-ignore
                  setIsModalOpen={setIsModalOpen}
                  id={currentQuestion?.id}
                  isModalOpen={isModalOpen}
                />
              )}
            </>
          ) : (
            <>
              <Button
                margin
                title={
                  showAnswer || localShowAnswer ? 'Hide Answer' : 'Show Answer'
                }
                onClick={(e) => {
                  e.preventDefault();
                  if (setShowAnswers) {
                    setShowAnswers(!showAnswer);
                  } else {
                    setLocalShowAnswer(!localShowAnswer);
                  }
                }}
              />
              <Button
                title={'Back to edit'}
                onClick={() => {
                  // @ts-ignore
                  dispatch(setClosePreview());
                }}
              />
            </>
          )}
        </div>
      </div>

      <div
        style={{
          position: 'relative',
          width: `${
            currentQuestion?.image && currentQuestion?.image?.width
              ? currentQuestion?.image?.width
              : 400
          }px`,
          height: `${
            currentQuestion?.image && currentQuestion?.image.height
              ? currentQuestion?.image?.height
              : 400
          }px`,
        }}
      >
        {/* Response Element */}
        {Array.isArray(elements) &&
          elements.map((element, key) => (
            <Rnd
              key={key}
              default={{
                x: element.x,
                y: element.y,
                width: element.width,
                height: element.height,
              }}
              bounds="parent"
              disableDragging={true}
              enableResizing={false}
              style={{
                position: 'absolute',
                width: '50px',
                height: '50px',
                border: '1px dotted #00FF00',
                cursor: 'move',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              {Array.isArray(element?.template) &&
                element.template.map((elm, key) => (
                  <Fragment key={key}>
                    {elm.type === 'txt' ? (
                      //@ts-expect-error
                      <math-field 
                        readonly={true}
                        style={
                          more_options && more_options?.layout.fontSize ?
                          {fontSize: more_options?.layout.fontSize, overflow: 'hidden' } : 
                          {}
                        }
                      >
                        {elm.content}
                        {/* @ts-expect-error */}
                      </math-field>
                    ) : (
                      <div
                        style={{
                          width: '16px',
                          height: '12px',
                          border: '1px solid #333',
                          cursor: 'move',
                          display: 'inline-block',
                        }}
                      ></div>
                    )}
                  </Fragment>
                ))}

              <span
                style={{
                  color: '#fff',
                  position: 'absolute',
                  top: '-17px',
                  left: '-10px',
                  width: '22px',
                  height: '22px',
                  backgroundColor: '#444',
                  borderRadius: '50%',
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  textAlign: 'center',
                  paddingBottom: '2px',
                }}
              >
                {element.id}
              </span>
            </Rnd>
          ))}

        {/* End Response Element */}
        {currentQuestion?.image && (
          <div
            style={{
              width:
                currentQuestion?.image && currentQuestion?.image.width
                  ? currentQuestion.image.width
                  : 400,
              height:
                currentQuestion?.image && currentQuestion?.image?.height
                  ? currentQuestion.image.height
                  : 400,
            }}
          >
            <img
              src={currentQuestion.image.src}
              alt={currentQuestion.image.alt}
              title={currentQuestion.image.title || ''}
              className={`border border-red-800 h-full w-full`}
            />
          </div>
        )}
      </div>

      {(localShowAnswer || showAnswer) && (
        <ul className="w-full flex flex-col p-2">
          <p className="font-semibold">Answers: </p>

          {Array.isArray(validResponse?.value) &&
            validResponse?.value.map((vr, index) => (
              <li className="mt-4" key={index}>
                <span className="px-2 bg-slate-500 text-white">
                  {index + 1}
                </span>{' '}
                {/* @ts-expect-error */}
                <math-field readonly={true}>
                  {vr?.value || ''}
                {/* @ts-expect-error */}
                </math-field>
              </li>
            ))}
        </ul>
      )}
    </QuestionContent>
  );
}
