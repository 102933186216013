import React, {ReactNode, DragEvent, ChangeEvent} from 'react';
import {Rnd} from 'react-rnd';
import {AiOutlineCloseCircle} from 'react-icons/ai';
import {DivContainer, DeleteIcon, StyledInput} from '../ImageWithText/styled';

interface ResizableDivContainerProps {
    div: {
        width?: number;
        height?: number;
        x: number;
        y: number;
        content?: any;
        options:any[];
    };
    onResize?: (size: { width: number; height: number }) => void;
    onDrag?: (position: { x: number; y: number }) => void;
    onDelete?: () => void;
    onDrop?: (e: DragEvent<HTMLDivElement>) => void;
    onChange?: (e: ChangeEvent<HTMLSelectElement>) => void; // Fix: Use ChangeEvent for input change
    placeholder?: string;
    selectedOptionId?: string;
    index: number;
    fontSize: string;
}

const ResizableDivContainer: React.FC<ResizableDivContainerProps> = ({
      div,
      onResize,
      onDrag,
      onDelete,
      onDrop,
      index,
      onChange,
      selectedOptionId,
      placeholder,
      fontSize,
      }) => {
    return (
        <Rnd
            bounds="parent"
            size={{width: div.width || 100, height: div.height || 40}}
            position={{x: div.x, y: div.y}}
            onResize={(e, direction, ref, delta, position) => {
                if(onResize) onResize({width: ref.offsetWidth, height: ref.offsetHeight});
            }}
            onDrag={(e, d) => {
              if (onDrag) onDrag({x: d.x, y: d.y})
            }}
        >
            <DivContainer 
                onDrop={(e) => onDrop(e)} 
                onDragOver={(e) => e.preventDefault()} 
                data-div={div}
                style={{width: '100%', height: '100%', padding: 5}}
            >
                <div 
                    className="flex flex-col w-full"
                    style={{fontSize}}
                >
                    <label className='mb-1' htmlFor="">
                        label {index + 1}
                    </label>
                    <select
                        className="block w-3/4 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset  focus:ring-2 focus:ring-inset focus:ring-red-600 sm:max-w-xs sm:text-sm sm:leading-6"
                        onChange={onChange}
                        value = {selectedOptionId ? selectedOptionId : ""}
                        required={true}
                        style={{fontSize}}
                    >
                        <option 
                            value=""
                            disabled
                        >
                            {placeholder ? placeholder : 'Select...'}
                        </option>
                        {
                            div?.options.map(opt => (
                                <option 
                                    value={opt?.id}
                                    key = {opt?.id}
                                >
                                    {opt?.option}
                                </option>
                            ))
                        }
                    </select>
                </div>

                {
                    !!onDelete && 
                    <DeleteIcon onClick={onDelete}>
                        <AiOutlineCloseCircle/>
                    </DeleteIcon>
                }
            </DivContainer>
        </Rnd>
    );
};

export default ResizableDivContainer;
