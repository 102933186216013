import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import DraggableElement from "../partials/DraggableElement/DraggableElement";
import Button from "components/Button";
import DroppableArea from "../partials/DroppableArea/DroppableArea";
import { TOptionItem } from "types";
import { useSelector } from "react-redux";
import { RootState } from "redux/store";

type ClassificationBlocPrevProps = {
  columnTitles: TOptionItem[];
  rowsTitles: TOptionItem[];
  possibRes: TOptionItem[];
  correctAnswers?: string[][];
  handlePointsChange?: (e: any) => void;
  editMode?: boolean;
  showAnswer?: boolean;
  score?: number;
  handleDataRest?: any;
};

export const ClassificationBlocPrev: React.FC<ClassificationBlocPrevProps> = ({
  columnTitles,
  rowsTitles,
  possibRes,
  correctAnswers,
  handlePointsChange,
  editMode,
  showAnswer,
  score,
  handleDataRest,
}) => {
  const { t } = useTranslation();
  const colRef = useRef<Array<HTMLSpanElement | null>>([]);
  const rowRef = useRef<Array<HTMLSpanElement | null>>([]);

  const row_min_height = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["class"] as any)?.more_options?.ui_style
        ?.row_min_height
  );
  const possibility_list_position = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["class"] as any)?.more_options?.ui_style
        ?.possibility_list_position
  );
  
  const [droppedItems, setDroppedItems] = useState<{ [key: number]: string[] }>(
    {}
  );
  
  const [availableItems, setAvailableItems] = useState<string[]>(
    possibRes?.map((item) => item.label)
  );

  useEffect(() => {
    colRef.current = colRef.current.slice(0, columnTitles?.length);
  }, [columnTitles]);

  useEffect(() => {
    rowRef.current = rowRef.current.slice(0, rowsTitles?.length);
  }, [rowsTitles]);
  const more_options = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["class"] as any)?.more_options
  );
  const getLabel = (index: number) => {
    let label = "";
    if (more_options?.ui_style?.validation_stem_numeration) {
      switch (more_options?.ui_style?.validation_stem_numeration) {
        case "numerical":
          label = (index + 1).toString();
          break;
        case "lower-alpha":
          label = String.fromCharCode(97 + index);
          break;
        case "upper-alpha":
          label = String.fromCharCode(97 + index).toUpperCase();
          break;
        default:
          break;
      }
    }
    return label;
  };
  useEffect(() => {
    columnTitles?.forEach((item, i) => {
      if (colRef.current[i]) {
        colRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
      }
    });
  }, [columnTitles]);

  useEffect(() => {
    rowsTitles?.forEach((item, i) => {
      if (rowRef.current[i]) {
        rowRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
      }
    });
  }, [rowsTitles]);

  console.log("possibility_list_position",possibility_list_position)
  const handleDrop = (id: number, label: string) => {
    if (id === -1) {
      setAvailableItems((prev) => {
        if (!prev.includes(label)) {
          return [...prev, label];
        }
        return prev;
      });
      setDroppedItems((prev) => {
        const updatedItems = { ...prev };
        Object.keys(updatedItems).forEach((key) => {
          updatedItems[key] = updatedItems[key].filter(
            (item) => item !== label
          );
        });
        return updatedItems;
      });
    } else {
      setDroppedItems((prev) => {
        const updatedItems = { ...prev };
        Object.keys(updatedItems).forEach((key) => {
          updatedItems[key] = updatedItems[key].filter(
            (item) => item !== label
          );
        });
        if (!updatedItems[id]) {
          updatedItems[id] = [];
        }
        updatedItems[id].push(label);
        return updatedItems;
      });

      setAvailableItems((prev) => prev.filter((item) => item !== label));
    }
  };
  const draggableArea = () => {
    return (
      <div className="overflow-x-scroll pb-4" style={row_min_height ? {minHeight: `${row_min_height}px`} : {}}>
        <table>
          <tbody>
            <tr>
              <th>&nbsp;</th>
              {columnTitles?.map((item, key) => (
                <th
                  key={key}
                  className="p-2 border-solid border border-grey-500 my-4 rounded-md min-w-[20vw] min-h-[48px]"
                >
                  <span ref={(el) => (colRef.current[key] = el)} />
                </th>
              ))}
            </tr>

            {rowsTitles?.map((item, rowIndex) => (
              <tr key={rowIndex}>
                <td className="p-2 border-solid border border-grey-500 my-4 rounded-md min-h-[48px]">
                  <span ref={(el) => (rowRef.current[rowIndex] = el)} />
                </td>
                {columnTitles?.map((_, colIndex) => (
                  <th
                    key={colIndex}
                    className="p-2 border-solid border border-grey-500 my-4 rounded-md min-h-[48px]"
                  >
                    <DroppableArea
                      id={rowIndex * columnTitles.length + colIndex}
                      onDrop={handleDrop}
                      droppedItems={
                        droppedItems[
                          rowIndex * columnTitles.length + colIndex
                        ] || []
                      }
                    />
                    {showAnswer &&
                      correctAnswers &&
                      correctAnswers[
                        rowIndex * columnTitles.length + colIndex
                      ]?.map((answer, key) => (
                        <div
                          key={key}
                          className={`border-2 p-1 my-1 ${
                            droppedItems[
                              rowIndex * columnTitles.length + colIndex
                            ]?.includes(answer)
                              ? "border-green-500"
                              : "border-red-500"
                          }`}
                          
                        >
                          <span>{getLabel(rowIndex * columnTitles.length + colIndex)}. </span>
                          <span dangerouslySetInnerHTML={{ __html: answer }} />
                        </div>
                      ))}
                  </th>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };
  const getDroppableArea = () => (
    <DroppableArea
      id={-1}
      onDrop={handleDrop}
      droppedItems={availableItems}
      className="flex items-center gap-1 flex-wrap bg-red-500"
    />
  );

  return (
    <DndProvider backend={HTML5Backend}>
      {handleDataRest && (
        <Button
          variant="contained"
          title={t("reset")}
          onClick={handleDataRest}
        />
      )}

      <div className="mt-4">
        {/* Conditional rendering of DroppableArea based on possibility_list_position */}
        {possibility_list_position === "top" && (
          <div className="mb-4">{getDroppableArea()}</div>
        )}
        {possibility_list_position !== "left" &&
          possibility_list_position !== "right" &&
          draggableArea()}
        {possibility_list_position === "bottom" && (
          <div className="mt-4">{getDroppableArea()}</div>
        )}

        {possibility_list_position === "left" && (
          <div style={{ display: "flex" }}>
            <div style={{ marginRight: "1rem" }}>{getDroppableArea()}</div>
            {draggableArea()}
          </div>
        )}

        {possibility_list_position === "right" && (
          <div style={{ display: "flex" }}>
            {draggableArea()}
            <div style={{ marginLeft: "1rem" }}>{getDroppableArea()}</div>
          </div>
        )}

        {editMode && (
          <div
            className="flex items-center gap-10 p-2"
            style={{
              border: "1px solid #eee",
              borderRadius: "6px",
              width: "300px",
            }}
          >
            <span>{t("points")}</span>
            {score ? (
              <input
                className="outline-none"
                type="number"
                value={score}
                readOnly
              />
            ) : (
              <input
                className="outline-none"
                placeholder="100"
                type="number"
                onChange={handlePointsChange}
              />
            )}
          </div>
        )}
      </div>
    </DndProvider>
  );
};

ClassificationBlocPrev.defaultProps = {
  editMode: false,
};
