
const VerticalInput = ({isCheckBox, isActive, handleItemClicked, item, children, otherStyle = {}} : 
    {isCheckBox?: boolean; isActive: boolean; handleItemClicked: Function; item: any; children: React.ReactNode; otherStyle?: any}) => {
        
    return(
        <div
            id="checkbox"
            className={`mt-5 mb-5 p-5 inline-flex flex-col-reverse justify-center items-center gap-3`}
            style={{
                minHeight: '45px',
                border: '1px solid #eee',
                borderRadius: '6px',
                ...otherStyle
            }}
        >    
            <input
                className="bg-[#DE2128]"
                type={isCheckBox ? "checkbox" :"radio"}
                checked={isActive}
                onChange={(e) => {handleItemClicked(item.id)}}
            />
            {children}
        </div>
    )
}

export default VerticalInput;