export const options = [
  { id: 'Admin', label: 'Admin' },
  { id: 'Editor', label: 'Editor' },
  { id: 'Teacher', label: 'Teacher' },
];
export const bankAccess = [
  { id: '1', label: 'Production' },
  { id: '2', label: 'Development' },
];
export const subjectsRoles = [
  { id: 'Question Manager', label: 'Question Manager' },
  { id: 'Publishing Development', label: 'Publishing Development' },
  { id: 'Tag Manager', label: 'Tag Manager' },
];
export const gradeRoles = [
  { id: 'User Management', label: 'User Management' },
  { id: 'Reports', label: 'Reports' },
  { id: 'Scoring', label: 'Scoring' },
];

export const subjectsDropDownOptions = [
  { id: 0, value: '', label: 'Select an option' },
  { id: 1, value: 'All', label: 'All' },
  { id: 2, value: 1, label: '1' },
  { id: 3, value: 2, label: '2' },
  { id: 4, value: 3, label: '3' },
];

export const userRolesDropDownOptions = [
  /*  { id: 0, value: "", label: "Select an option" },*/
  { id: 1, value: 'Admin', label: 'Admin' },
  { id: 2, value: 'Teacher', label: 'Teacher' },
  { id: 3, value: 'Editor', label: 'Editor' },
];

export const statusUsersDropDownOptions = [
  { id: -1, value: '', label: 'Select an option' },
  { id: 1, value: 1, label: 'Active' },
  { id: 2, value: 2, label: 'In progress' },
  { id: 0, value: 0, label: 'Draft' },
];

export const accessTypeeDropDownOptions = [
  { id: -1, value: '', label: 'Select an option' },
  { id: 1, value: 'Production', label: 'Production' },
  { id: 0, value: 'Development', label: 'Development' },
];

export const accessTypes = [
  { id: 1, value: true, label: 'Public' },
  { id: 2, value: false, label: 'Private' },
];

export const statusOptions = [
  { id: 0, value: true, label: 'Published' },
  { id: 1, value: false, label: 'Unpublished' },
];

export const statusOptionsAll = [
  { id: 0, value: 0, label: 'Unpublished' },
  { id: 1, value: 1, label: 'Published' },
  { id: 2, value: 2, label: 'All' },
];

export const gradeDropDownOptions = [
  { id: 0, value: '', label: 'Select an option' },
  { id: 1, value: 'All', label: 'All' },
  { id: 2, value: 1, label: '1' },
  { id: 3, value: 2, label: '2' },
  { id: 4, value: 3, label: '3' },
];

export const bankAccessOptions = [
  { id: 1, value: 1, label: 'Production' },
  { id: 2, value: 2, label: 'Development' },
];

export const userStatusOptions = [
  { id: 1, value: 1, label: 'Active' },
  { id: 0, value: 0, label: 'Inactive' },
  { id: 2, value: 2, label: 'Draft' },
];

// Fake data until get backend API
export const dataUserManagement = [
  {
    id: 1,
    assessment_name: 'Lorem Ipsum',
    subject: 'Maths',
    grade: 3,
    tags: 'Lorem',
    created_date: '28-12-2022',
    access_type: 'Private',
    status: true,
  },
  {
    id: 2,
    assessment_name: 'Lorem Ipsum',
    subject: 'Maths',
    grade: 3,
    tags: 'Lorem',
    created_date: '28-12-2022',
    access_type: 'Private',
    status: true,
  },
  {
    id: 3,
    assessment_name: 'Lorem Ipsum',
    subject: 'Maths',
    grade: 3,
    tags: 'Lorem',
    created_date: '28-12-2022',
    access_type: 'Private',
    status: true,
  },
  {
    id: 4,
    assessment_name: 'Lorem Ipsum',
    subject: 'Maths',
    grade: 3,
    tags: 'Lorem',
    created_date: '28-12-2022',
    access_type: 'Private',
    status: true,
  },
  {
    id: 5,
    assessment_name: 'Lorem Ipsum',
    subject: 'Maths',
    grade: 3,
    tags: 'Lorem',
    created_date: '28-12-2022',
    access_type: 'Private',
    status: true,
  },
  {
    id: 6,
    assessment_name: 'Lorem Ipsum',
    subject: 'Maths',
    grade: 3,
    tags: 'Lorem',
    created_date: '28-12-2022',
    access_type: 'Private',
    status: true,
  },
  {
    id: 7,
    assessment_name: 'Lorem Ipsum',
    subject: 'Maths',
    grade: 3,
    tags: 'Lorem',
    created_date: '28-12-2022',
    access_type: 'Private',
    status: true,
  },
  {
    id: 8,
    assessment_name: 'Lorem Ipsum',
    subject: 'Maths',
    grade: 3,
    tags: 'Lorem',
    created_date: '28-12-2022',
    access_type: 'Private',
    status: true,
  },
  {
    id: 9,
    assessment_name: 'Lorem Ipsum',
    subject: 'Maths',
    grade: 3,
    tags: 'Lorem',
    created_date: '28-12-2022',
    access_type: 'Private',
    status: true,
  },
  {
    id: 10,
    assessment_name: 'Lorem Ipsum',
    subject: 'Maths',
    grade: 3,
    tags: 'Lorem',
    created_date: '28-12-2022',
    access_type: 'Private',
    status: true,
  },
];

export const staticCards = [
  {
    title: 'Sessions Delivered',
    number: 5500,
    path: '',
  },
  {
    title: 'Questions Delivered',
    number: 52.374,
    path: '/questions-delivered',
  },
  {
    title: 'Assessments',
    number: 5500,
    path: '/assessments-delivered',
  },
  {
    title: 'No. Of Users',
    number: 5500,
    path: '/user-management',
  },
];

export const authorStaticCards = [
  {
    title: 'Sessions Created',
    number: 100,
    path: '/assessment-listing',
  },
  {
    title: 'Questions Created',
    number: 30,
    path: '/question-listing',
  },
  {
    title: 'Tags Created',
    number: 15,
  },
];

export const data = {
  labels: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
  datasets: [
    {
      label: 'Visitors',
      data: [150, 200, 300, 250, 400, 350, 200],
      backgroundColor: 'rgba(222, 33, 44)',
      borderColor: 'rgba(222, 33, 44)',
    },
    {
      label: 'Page Views',
      data: [300, 400, 500, 450, 600, 550, 400],
      backgroundColor: 'rgba(222, 33, 44)',
      borderColor: 'rgba(222, 33, 44)',
    },
  ],
};

export const circleData = [
  {
    id: 'java',
    label: 'java',
    value: 195,
    color: 'hsl(90, 70%, 50%)',
  },
  {
    id: 'erlang',
    label: 'erlang',
    value: 419,
    color: 'hsl(56, 70%, 50%)',
  },
  {
    id: 'ruby',
    label: 'ruby',
    value: 407,
    color: 'hsl(103, 70%, 50%)',
  },
  {
    id: 'haskell',
    label: 'haskell',
    value: 474,
    color: 'hsl(186, 70%, 50%)',
  },
  {
    id: 'go',
    label: 'go',
    value: 71,
    color: 'hsl(104, 70%, 50%)',
  },
];

export const barData = [
  {
    day: 'Monday',
    degress: 59,
  },
  {
    day: 'Tuesday',
    degress: 61,
  },
  {
    day: 'Wednesday',
    degress: 55,
  },
  {
    day: 'Thursday',
    degress: 78,
  },
  {
    day: 'Friday',
    degress: 71,
  },
  {
    day: 'Saturday',
    degress: 56,
  },
  {
    day: 'Sunday',
    degress: 67,
  },
];

export const acceptedFileTypes = [
  {
    label:'allow_jpg',
    value:'JPG'
  },
  {
    label:'allow_gif',
    value:'GIF'
  },
  {
    label:'allow_png',
    value:'PNG'
  },
  {
    label:'allow_pdf',
    value:'PDF'
  },
  {
    label:'allow_csv',
    value:'CSV'
  },
  {
    label:'allow_rtf',
    value:'RTF'
  },
  {
    label:'allow_txt',
    value:'TXT'
  },
  {
    label:'allow_xps',
    value:'XPS'
  },
  {
    label:'allow_zip',
    value:'ZIP'
  },
  {
    label:'allow_ms_word',
    value:'Word'
  },
  {
    label:'allow_ms_excel',
    value:'Excel'
  },
  {
    label:'allow_ms_powerpoint',
    value:'Powerpoint'
  },
  {
    label:'allow_ms_publisher',
    value:'Publisher'
  },
  {
    label:'allow_open_office',
    value:'Open Office'
  },
  {
    label:'allow_matlab',
    value:'MATLAB'
  },
  {
    label:'allow_altera_quartus',
    value:'Quartus'
  },
  {
    label:'allow_verilog',
    value:'Verilog'
  },
  {
    label:'allow_v',
    value:'V'
  },
  {
    label:'allow_h',
    value:'H'
  },
  {
    label:'allow_c',
    value:'C'
  },
  {
    label:'allow_cpp',
    value:'C++'
  } ,
  {
    label:'allow_s',
    value:'S'
  }  ,
  {
    label:'allow_assembly',
    value:'Assembly'
  }  ,
  {
    label:'allow_labview',
    value:'LabVIEW'
  } 
];
