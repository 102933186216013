import DOMPurify from 'dompurify';
import { useEffect, useRef } from 'react';
import { useDrag } from 'react-dnd';
import { ItemTypes } from 'types';

interface DraggableElementProps {
  label: string;
}

const DraggableElement: React.FC<DraggableElementProps> = ({ label }) => {
  const elRef = useRef<HTMLDivElement>(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.MATCH,
    item: { label },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }), [label]);

  useEffect(() => {
    if (elRef.current) {
      elRef.current.innerHTML = DOMPurify.sanitize(label);
    }
  }, [label]);

  return (
    <div
      ref={drag}
      style={{
        opacity: isDragging ? 0.5 : 1,
        cursor: 'move',
      }}
    >
      <div
        className="p-2 m-4 w-auto border-solid border border-[#4a9d2d] rounded-md bg-[#4a9d2d] text-white"
        ref={elRef}
      />
    </div>
  );
};

export default DraggableElement;
