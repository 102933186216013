import axios from 'axios';
import { getParsedJwt } from 'utils';

type Authentication = {
  id: number;
  lastRequestAt?: number;
  token: string;
  rememberMe: boolean;
};

const MAX_BETWEEN_TWO_REQUEST = 60 * 60;

const generateLastRequestAt = (): number => {
  return Math.floor(Date.now() / 1000);
};

const checkIfAlwaysAuthenticated = (auth: Authentication): boolean => {
  if (!auth.rememberMe) {
    const now = generateLastRequestAt();

    if (now - auth.lastRequestAt > MAX_BETWEEN_TWO_REQUEST) {
      removeAuthentication();

      return false;
    }
  }

  return true;
};

const checkTokenExpirationMiddleware = (auth: Authentication): boolean => {
  const now = generateLastRequestAt();

  if (getParsedJwt<any>(auth.token)?.exp < now) {
    removeAuthentication();
    return false;
  }

  return true;
};

const getAuthentication = (): Authentication => {
  const auth = localStorage.getItem('auth');

  if (auth) return JSON.parse(auth);

  return null;
};

const setAuthentication = (auth: Authentication): void => {
  auth.lastRequestAt = generateLastRequestAt();
  localStorage.setItem('auth', JSON.stringify(auth));

  axios.defaults.headers.common = {
    Authorization: `Bearer ${auth.token}`,
    'Content-Type': 'application/json',
  };
};

const removeAuthentication = (): void => {
  localStorage.removeItem('auth');
};

const reloadAuthentication = (): boolean => {
  if (!hasAuthentication()) return false;

  const auth = getAuthentication();

  setAuthentication(auth);

  // axios.defaults.headers.common = {
  //   Authorization: `Bearer ${auth.token}`,
  //   'Content-Type': 'application/json',
  // };

  return true;
};

const hasAuthentication = (): boolean => {
  const auth = getAuthentication();

  // if (auth) return checkIfAlwaysAuthenticated(auth);
  if (auth) return checkTokenExpirationMiddleware(auth);

  return false;
};

export {
  setAuthentication,
  removeAuthentication,
  hasAuthentication,
  reloadAuthentication,
  getAuthentication,
};
