import { useEffect, useRef, useState } from 'react';
import { QuestionContent } from './styled';
import Button from '../../../components/Button';
import { setClosePreview } from '../../../redux/slices/PreviewSlice';
import { useNavigate } from 'react-router-dom';
import { setTypeQuestionOpenInEditMode } from '../../../redux/slices/EditModeSlice';
import { useAppDispatch } from 'hooks/useAppDispatch';
import { MathfieldElement } from 'mathlive';
import QuestiontypeModal from 'components/QuestiontypeModal';
import CustomJoditEditor from 'components/CustomJoditEditor';
import { readonlyMathConfig } from 'components/CustomJoditEditor/JoditConfig';
import './customForMath.css'
import { MathJax, MathJaxContext } from 'better-react-mathjax';
import DOMPurify from 'dompurify';

const mathJaxConfig = {
  loader: { load: ['input/tex', 'output/svg'] },
};

const PreviewClozeMath = ({
  currentQuestion,
  showAnswer = false,
  setShowAnswers = null,
  parentMode = 'assessment',
  editMode = false,
}) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [elements, setElements] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  // const expressionRefs = useRef([]);
  const validResponse = currentQuestion?.correct_answer?.valid_response;
  const more_options = currentQuestion?.more_options;


  function insertResponse(array, insertObject) {
    const result = [];
    for (let i = 0; i < array.length; i++) {
      result.push(array[i]);
      if (i < array.length - 1) {
        result.push(insertObject);
      }
    }
    return result;
  }

  /* useEffect(() => {
     let inputString = currentQuestion.template_response;
     if (inputString) {
       // const regex = /({\\colorbox{#e4e4e4}{{Response}}})|([^{}]+)/g;
       // const regex = /(<strong>Response<\/strong>)|([^<>{}]+)/g;
       const regex = "<strong>Response</strong>";
       const parts = inputString.split(regex);
       let T = [];
       for (let i = 0; i < parts.length; i++) {
         if (parts[i] !== undefined && parts[i] !== '') {
           if (parts[i] === '<strong>Response</strong>') {
             T.push({ type: 'Res', content: 'Response' });
           } else {
             T.push({ type: 'txt', content: parts[i] });
           }
         }
       }
       
       T = insertResponse(T, { type: 'Res', content: 'Response' } );
       setElements(T);
     }
   }, []);*/

  // New code to maintain Response properly in the table format
  useEffect(() => {
    let inputString = currentQuestion.template_response;
    console.log(inputString, " Input String ");
    if (inputString) {
      // const regex = /({\\colorbox{#e4e4e4}{{Response}}})|([^{}]+)/g;
      // const regex = /(<strong>Response<\/strong>)|([^<>{}]+)/g;
      const regex = "<strong>Response</strong>";
      const parts = inputString.split(regex);
      let T = [];
      for (let i = 0; i < parts.length; i++) {
        if (parts[i] !== undefined && parts[i] !== '') {
          if (parts[i] === '<strong>Response</strong>') {
            T.push({ type: 'Res', content: 'Response' });
          } else {
            T.push({ type: 'txt', content: parts[i] });
          }
        }
      }

      T = insertResponse(T, { type: 'Res', content: 'Response' });
      setElements(T);



      // New code here

      const replaceResponseWithMathlive = () => {
        console.log('Entering');
        const container = document.getElementById(`display-content-${currentQuestion?.id}`);
        if (container) {
          console.log('Entering22');
          const responseSpans = container.querySelectorAll('span#btnresponse');
          console.log(responseSpans, " Preview Cloze Math");
          responseSpans.forEach((span) => {
            console.log('span ');
            // const mathfield = new MathfieldElement();
            const mathInput = document.createElement('math-field');
            mathInput.style.border = '1px solid black';
            mathInput.style.padding = '5px';
            mathInput.style.margin = '5px 0';
            mathInput.style.minHeight = '50px';
         //   mathInput.style.width = "20%";
            mathInput.style.fontSize =more_options && more_options?.layout.fontSize ?
              more_options.layout.fontSize  : '12px';
            span.replaceWith(mathInput);
          });
        }
      };

      replaceResponseWithMathlive();

    }
  }, []);

  const handleEditClick = () => {
    dispatch(setTypeQuestionOpenInEditMode(currentQuestion?.type));
    navigate(`/edit-subquestion/${currentQuestion.id}`);
  };

  const correctTextRef = useRef<Array<HTMLSpanElement | null>>([]);

  const getTheContent = (content: string) => {
    let newContent = content;
    const regex = /<p(?![^<]*<\/p>)/g;
    newContent = newContent.replace(regex, '<span');
    return newContent
  }

  useEffect(() => {
    elements.forEach((item, i) => {
      if (correctTextRef.current[i]) {
        const content = getTheContent(item?.content);
        correctTextRef.current[i].innerHTML = item?.content == "Response" ?
          "" :
          DOMPurify.sanitize(content);
      }
    });
  }, [elements]);

  // useEffect(() => {
  //   // Initialize MathfieldElements after expressions are set
  //   expressionRefs.current.forEach((ref, index) => {
  //     if (ref && !ref.hasChildNodes() && elements[index]) {
  //       if (elements[index]?.type && elements[index].type != 'txt') {
  //         const mf = document.createElement('math-field') as MathfieldElement;
  //         mf.value = elements[index].content;
  //         if (!location.pathname.includes('/edit-question/')) {
  //           mf.setAttribute('read-only', 'true');
  //           // mf.setAttribute(
  //           //   'style',
  //           //   'border: 1px solid black; padding: 0 8px; height: 100%'
  //           // );
  //         }
  //         const style = handleResponseStyle(more_options, elements, index);
  //           mf.setAttribute(
  //             'style',
  //             style
  //           );
  //         ref.appendChild(mf);
  //       } else {
  //         const mf = document.createElement('math-field') as MathfieldElement;
  //         mf.value = elements[index].content;
  //         mf.setAttribute('read-only', 'true');
  //         ref.appendChild(mf);
  //       }
  //     }
  //   });
  // }, [elements]);

  const handleResponseStyle = (moreOptions, correctAnswers, index) => {
    let style: string = 'border: 1px solid black; padding: 0 8px; height: 100%;'
    const resOptionsIndividaul = moreOptions?.response_options_individaul || [];
    if (moreOptions) {
      if (moreOptions?.response_options?.width) {
        style += `width: ${moreOptions.response_options.width}px; `
      }
      if (moreOptions?.response_options?.height) {
        style += `height: ${moreOptions.response_options.height}px; `
      }
      if (moreOptions?.layout?.response_min_width) {
        style += `min-width: ${moreOptions?.layout?.response_min_width}px;`
      }
      if (moreOptions?.layout?.fontSize) {
        style += `font-size: ${moreOptions?.layout?.fontSize}; `
      }
    }
    if (moreOptions && resOptionsIndividaul.length > 0) {
      // we collect all responses first
      const allRes = correctAnswers.filter(ca => ca?.type == 'Res');
      const currentResFromcorrectanswers = correctAnswers[index];
      const currentResIndex = allRes.findIndex((res, idx) => currentResFromcorrectanswers === res);
      if (currentResIndex > -1) {
        const currentResOptions = resOptionsIndividaul.find((_item, idx) => idx == currentResIndex);
        if (currentResOptions) {
          if (currentResOptions?.width) {
            style += `width: ${currentResOptions.width}px; `
          }
          if (currentResOptions?.height) {
            style += `height: ${currentResOptions.height}px; `
          }
        }
      }
    }
    return style;
  }

  return (
    <MathJaxContext config={mathJaxConfig}>
      <QuestionContent parentMode={parentMode === 'question'}>
        <div className="flex flex-wrap gap-5 justify-between mb-5">
          <div className="w-full lg:w-[70%]">
            <div className="bg-light-accent flex p-5 gap-3 rounded-lg">
              <p className="font-bold text-sm">Question :&nbsp;</p>
              <span
                dangerouslySetInnerHTML={{
                  __html: ` ${currentQuestion?.question}`,
                }}
              />
            </div>
          </div>

          <div className="flex gap-4">
            {editMode ? (
              <>
                <Button
                  margin
                  title={'Go to edit'}
                  onClick={() => handleEditClick()}
                />
                <Button
                  title={'Delete'}
                  onClick={(e) => {
                    e.preventDefault();
                    setIsModalOpen(true);
                  }}
                />

                {isModalOpen && (
                  <QuestiontypeModal
                    // @ts-ignore
                    setIsModalOpen={setIsModalOpen}
                    id={currentQuestion?.id}
                    isModalOpen={isModalOpen}
                  />
                )}
              </>
            ) : (
              <>
                <Button
                  margin
                  title={showAnswer ? 'Hide Answer' : 'Show Answer'}
                  onClick={() => {
                    if (setShowAnswers) setShowAnswers(!showAnswer);
                  }}
                />

                <Button
                  title={'Back to edit'}
                  onClick={() => {
                    dispatch(setClosePreview());
                  }}
                />
              </>
            )}
          </div>
        </div>

        <div style={{
          // display: 'flex',
          // alignItems: 'center',
          border: '1px solid #D9D9D9',
          padding: '24px',
          marginBottom: '10px',
          backgroundColor: '#f0f0f0',
        }}>
          <div id={`display-content-${currentQuestion?.id}`} className="display-content" dangerouslySetInnerHTML={{ __html: currentQuestion?.template_response }} />

          {/* {elements.map((el, index) => {
            if (el?.content == "Response") {
              return (
                <span
                  key={index}
                  className="w-fit bg-white inline"
                  style={more_options && more_options?.layout.fontSize ?
                    { fontSize: more_options.layout.fontSize } :
                    {}
                  }
                >
                  {/* @ts-expect-error }
                  <math-field>
                    <span
                      ref={(el) => (correctTextRef.current[index] = el)}
                      key={index}
                    />
                    {/* @ts-expect-error }
                  </math-field>
                </span>
              )
            }
            return (
              <MathJax
                key={index}
                style={{ display: 'inline' }}
              >
                <span
                  key={index}
                  ref={(el) => (correctTextRef.current[index] = el)}
                  style={more_options && more_options?.layout.fontSize ?
                    { fontSize: more_options.layout.fontSize } :
                    {}
                  }
                />
              </MathJax>
            )
          }
          )} */}
        </div>
        {showAnswer && (
          <ul className="w-full flex flex-col p-2">
            <p className="font-semibold">Answers: </p>

            {Array.isArray(validResponse?.value) &&
              validResponse?.value.map((vr, index) => (
                <li className="mt-4" key={index}>
                  <span className="px-2 bg-slate-500 text-white">
                    {index + 1}
                  </span>{' '}
                  {/* @ts-expect-error */}
                  <math-field readonly={true}>
                    {vr?.value || ''}
                    {/* @ts-expect-error */}
                  </math-field>
                </li>
              ))}
          </ul>
        )}
      </QuestionContent>
    </MathJaxContext>
  );
};

export default PreviewClozeMath;
