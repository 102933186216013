export const Colors = {
  forestGreen: '#FA9B31',
  forestGreen2: '#D6F4D2',
  forestGreen3: '#EEFDEC',
  forestGreen4: '#F9FFF9',
  pumpkin: '#FF6601',
  pumpkin2: '#FFDAC1',
  pumpkin3: '#FFF5E2',
  pumpkin4: '#FFF5E2',
  gray: '#eeeeee',
};

//   Datatable style
export const customStyles: any = {
  headRow: {
    style: {
      color: '#8A8A8A',
      backgroundColor: '#FFFFFF',
      borderBottom: '1px solid',
      // fontSize: '16px',
    },
  },
  head: {
    style: {
      // color: Colors.forestGreen,
      fontSize: '12px',
      fontWeight: 500,
    },
  },
  // tableWrapper: {
  //   style: {
  //     display: 'table',
  //   },
  // },
  // table: {
  //   style: {},
  // },

  tableWrapper: {
    style: {
      display: 'table',
    },
  },
  table: {
    style: { border: '1px solid #d9d9d9' },
  },
  rows: {
    style: {
      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#FAFAFA',
      },
    },
  },
  pagination: {
    style: {
      color: '#131313',
      fontSize: '13px',
      minHeight: '56px',
    },
  },
};

export const customQuestionStyles: any = {
  headRow: {
    style: {
      color: '#8A8A8A',
      backgroundColor: '#FFFFFF',
      borderBottom: '1px solid',
      // fontSize: '16px',
    },
  },
  head: {
    style: {
      // color: Colors.forestGreen,
      fontSize: '12px',
      fontWeight: 500,
    },
  },
  // tableWrapper: {
  //   style: {
  //     display: 'table',
  //   },
  // },
  // table: {
  //   style: {},
  // },

  tableWrapper: {
    style: {
      display: 'table',
    },
  },
  table: {
    style: { border: '1px solid #d9d9d9' },
  },
  rows: {
    style: {
      '&:hover': {
        backgroundColor: '#FAFAFA',
      },
    },
  },
  pagination: {
    style: {
      color: '#131313',
      fontSize: '13px',
      minHeight: '56px',
    },
  },
};
