import React, { useEffect, useRef } from 'react';
import DragContainer from 'components/DragContainer/DragContainer';
import DOMPurify from 'dompurify';
import { TOptionItem } from 'types';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

type SortableElementsProps = {
  columnTitles: TOptionItem[];
  handleColSort: () => void;
  dragItem: React.MutableRefObject<any>;
  dragOverItem: React.MutableRefObject<any>;
  correctAnswers?: TOptionItem[];
};

const SortableElements = ({
  columnTitles,
  handleColSort,
  dragItem,
  dragOverItem,
  correctAnswers,
}: SortableElementsProps): JSX.Element => {
  const textRef = useRef<Array<HTMLDivElement | HTMLButtonElement | null>>([]);
  const textCorrectAnswerRef = useRef<Array<HTMLDivElement | null>>([]);

  const list_style = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["ordlis"] as any)?.more_options?.ui_style
        ?.list_style
  );

  useEffect(() => {
    columnTitles?.forEach((item, i) => {
      const content = typeof item === 'string' ? item : item?.label ? item.label : '';
      if (textRef.current[i]) {
        textRef.current[i]!.innerHTML = DOMPurify.sanitize(content);
      }
    });
  }, [columnTitles, list_style]); // Ensure content updates when list_style changes

  const renderItems = () => {
    switch (list_style) {
      case 'inline':
        return (
          <div className="inline-flex gap-2">
            {columnTitles.map((item, i) => (
              <DragContainer
                index={i}
                key={item?.id}
                onDragEnd={handleColSort}
                dragItem={dragItem}
                dragOverItem={dragOverItem}
              >
                <div ref={(el) => (textRef.current[i] = el)} />
              </DragContainer>
            ))}
          </div>
        );
      default:
        return (
          <div className="flex flex-col gap-2">
            {columnTitles.map((item, i) => (
              <DragContainer
                index={i}
                key={item?.id}
                onDragEnd={handleColSort}
                dragItem={dragItem}
                dragOverItem={dragOverItem}
              >
                <div ref={(el) => (textRef.current[i] = el)} />
              </DragContainer>
            ))}
          </div>
        );
    }
  };

  return <>{renderItems()}</>;
};

export default SortableElements;
