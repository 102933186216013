import {createSlice, PayloadAction} from '@reduxjs/toolkit';

interface UserManagment {
  isLoading: boolean;
  allUsers: any[];
  users: object[];
  pagination: object;
  filters: object[];
}

const initialState: UserManagment = {
  allUsers: [],
  isLoading: true,
  users:[],
  pagination: {},
  filters:[],
};

const UserManagementSlice = createSlice({
  name: 'UserManagment',
  initialState,
  reducers: {
    usersListingRequest(state) {
      state.isLoading = true;
      state.users = [];
    },
    setUsersListing(state, action: PayloadAction<any>) {
      const { items, pagination, filters } = action.payload;
      // console.log(action.payload);
      state.isLoading = true;
      state.users = items;
      state.pagination = pagination;
      state.filters = filters;


    },
  }
});

export const { usersListingRequest, setUsersListing } = UserManagementSlice.actions;

export default UserManagementSlice.reducer;
