import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { BsBellFill, BsFillQuestionCircleFill } from 'react-icons/bs';
import profilePic from '../assets/images/profile-pic.png';
import { useNavigate } from 'react-router-dom';
import Font from 'react-font';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

interface HeaderProps {
  children?: ReactNode;
}

const Header: React.FC<HeaderProps> = ({ children }) => {
  const navigate = useNavigate();
  const [, setEnvironment] = useState('');
  const [accessTypes, setAccessTypes] = useState([]);
  const [selectedScope, setSelectedScope] = useState('');

  useEffect(() => {
    // Fetch the access token from local storage or any other appropriate source
    const persistRootString = localStorage.getItem('persist:root');
    if (persistRootString) {
      try {
        const persistRoot = JSON.parse(persistRootString);
        const authentication = JSON.parse(persistRoot.authentication);
        const user = authentication.user;
        const userAccessTypes = user.access_types;

        console.log('userAccessTypes', userAccessTypes);

        // Extract the names from accessTypes
        const accessTypeNames = userAccessTypes.map((type) => type);

        // Set accessTypes state
        setAccessTypes(userAccessTypes);

        // Retrieve the previously selected scope from local storage
        const selectedScopeFromLocalStorage = localStorage.getItem('selectedScope');

        if (selectedScopeFromLocalStorage) {
          setSelectedScope(selectedScopeFromLocalStorage);
          setEnvironment(selectedScopeFromLocalStorage);
        } else if (accessTypeNames.includes('Development')) {
          setEnvironment('Development');
          setSelectedScope('Development');
        } else if (accessTypeNames.includes('Production')) {
          setEnvironment('Production');
          setSelectedScope('Production');
        }
      } catch (error) {
        console.error('Error parsing localStorage data:', error);
      }
    } else {
      console.error('persist:root item not found in localStorage');
    }
  }, []);

  // Function to handle scope switching API call
  const switchScope = async (selectedScope: string) => {
    try {
      const response = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/switchScope`,
          { scope: selectedScope },
          { headers: { 'Content-Type': 'application/json' } }
      );
      if (response.status === 200) {
        // Scope switched successfully
        console.log('Scope switched successfully.');

        // Update local state with the selected scope
        setEnvironment(selectedScope);
        setSelectedScope(selectedScope);

        // Update local storage with the selected scope
        localStorage.setItem('selectedScope', selectedScope);

        // Reload the page upon success
        window.location.reload();
      } else {
        // Handle error response
        console.error('Error switching scope:', response.statusText);
      }
    } catch (error) {
      console.error('Error switching scope:', error.message);
    }
  };
  const isAdmin = localStorage.getItem('type');
  const isconnectAdmin = isAdmin?.includes('admin');
  const school_name = useSelector( (state: RootState) => (state?.authentication?.user?.school_name) || '');

  return (
      <Container>
        <StyledHeader>
          <Font family="Inter" weight={700}>
            <StyledTitle>
              {school_name}
              {isconnectAdmin && (
                  <select
                      id="environment-select"
                      style={{ paddingLeft: '71px', appearance: 'auto' }}
                      value={selectedScope}
                      onChange={(e) => switchScope(e.target.value)}
                  >
                    {/* Map over the access_types array to create options dynamically */}
                    {accessTypes.map((type, index) => (
                        <option key={index} value={type}>
                          {type}
                        </option>
                    ))}
                  </select>
              )}
            </StyledTitle>
          </Font>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <BsBellFill className="cursor-pointer text-[#E93837]" />
            <BsFillQuestionCircleFill
                onClick={() => navigate('/user-profile')}
                className="cursor-pointer text-[#E93837]"
            />
            <StyledIcon
                src={profilePic}
                onClick={() => navigate('/user-profile')}
                alt="user profile"
                className="cursor-pointer"
            />
          </div>
        </StyledHeader>
        {children}
      </Container>
  );
};

export default Header;

const Container = styled.div`
  width: 100%;
  height: auto;
  padding: 20px 35px 40px 35px;
  border-bottom: 1px solid #cccc;
  background-color: #fff;
`;

const StyledHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #241f20;
  padding-bottom: 20px;
  border-bottom: 0.5px solid #0000001f;
  font-family: Inter,serif;
`;

const StyledTitle = styled.h2`
  display: flex;
  align-items: center;
  font-size: 1.5rem;
  color: #000000;

  select {
    appearance: none;
    background: url('data:image/svg+xml;utf8,<svg fill="#000000" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/><path d="M0 0h24v24H0z" fill="none"/></svg>') no-repeat right 12px center/20px auto;
    padding-right: 30px; /* adjust based on icon size */
  }
`;

const StyledIcon = styled.img`
  width: 2rem;
  height: 2rem;
  cursor: pointer;
`;
