import React, { useState, useRef, useEffect } from "react";
import WaveSurfer from "wavesurfer.js";
import RecordPlugin from "wavesurfer.js/dist/plugins/record.esm.js";
import { MdFiberManualRecord } from "react-icons/md";
import { FaPause } from "react-icons/fa";

const AudioPlugin = ({ selectedControls }) => {
  const uiStyle = selectedControls.more_options.ui_style;
  const [wavesurfer, setWaveSurfer] = useState<WaveSurfer | null>(null);
  const [record, setRecord] = useState<any>(null);
  const [scrollingWaveform, setScrollingWaveform] = useState(false);
  const [isRecording, setIsRecording] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [showVolume, setShowVolume] = useState(false);
  const [timer, setTimer] = useState("00:00");
  const audioRef = useRef(null);
  const [volume, setVolume] = useState(1); // Initial volume

  const micRef = useRef<HTMLDivElement | null>(null);
  const recordingsRef = useRef<HTMLDivElement | null>(null);
  const micSelectRef = useRef<HTMLSelectElement | null>(null);
  const recordButtonRef = useRef<HTMLButtonElement | null>(null);
  const pauseButtonRef = useRef<HTMLButtonElement | null>(null);
  const volumeRangeRef = useRef<HTMLInputElement | null>(null);
  const recordingTimerRef = useRef<number>(0);
  const maxRecordingLength = selectedControls.options.max_length;

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(remainingSeconds).padStart(2, "0");
    return `${formattedMinutes}:${formattedSeconds}`;
  };
  // const maxRecordingLength = formatTime(selectedControls.max_length)

  useEffect(() => {
    const handleScrollingWaveformChange = () => {
      setScrollingWaveform(uiStyle.waveform);
      createWaveSurfer();
    };
    handleScrollingWaveformChange();
  }, [scrollingWaveform]);

  const createWaveSurfer = () => {
    if (wavesurfer) {
      wavesurfer.destroy();
    }
    const ws = WaveSurfer.create({
      container: micRef.current!,
      waveColor: "rgb(250, 155, 49)",
      progressColor: "rgb(100, 0, 100)",
      // height: 86,
      // width: 453,
      // normalize: true,
      barWidth: 3,
    });

    ws.on("ready", () => {
      // Handle waveform ready event
    });

    const rec = ws.registerPlugin(
      RecordPlugin.create({
        scrollingWaveform,
        renderRecordedAudio: false,
        maxLength: maxRecordingLength,
      })
    );

    rec.on("pause", () => {
      // Clear recording timer if paused
      if (recordingTimerRef.current) {
        window.clearTimeout(recordingTimerRef.current);
      }
    });

    rec.on("resume", () => {
      // Restart recording timer if resumed
      recordingTimerRef.current = window.setTimeout(() => {
        handleRecordingTimeout();
      }, maxRecordingLength * 1000); // Convert seconds to milliseconds
    });

    rec.on("stop", () => {
      // Clear recording timer when stopped
      if (recordingTimerRef.current) {
        window.clearTimeout(recordingTimerRef.current);
      }
    });

    rec.on("record-end", (blob: Blob) => {
      const recordedUrl = URL.createObjectURL(blob);
      setShowVolume(true)
      if (recordingsRef.current) {
        const wsRecorded = WaveSurfer.create({
          container: recordingsRef.current,
          waveColor: "rgb(200, 100, 0)",
          progressColor: "rgb(100, 50, 0)",
          url: recordedUrl,
        });

        wsRecorded.on("audioprocess", () => {
          const currentTime = wsRecorded.getCurrentTime();
          updateProgress(currentTime * 1000);
        });

        wsRecorded.on("finish", () => {
          setTimer("00:00");
        });

        if (uiStyle.play) {
          const playButton = document.createElement("button");
          playButton.textContent = "Play";
          playButton.onclick = () => wsRecorded.playPause();
          wsRecorded.on("pause", () => (playButton.textContent = "Play"));
          wsRecorded.on("play", () => {
            playButton.textContent = "Pause";
          });
          playButton.style.fontSize = "14px";
          playButton.style.color = "red";
          playButton.style.margin = "20px";

          recordingsRef.current.appendChild(playButton);
        }

        if (recordingsRef.current) {
          const downloadLink = document.createElement("a");
          downloadLink.href = recordedUrl;
          downloadLink.download =
            "recording." + (blob.type.split(";")[0].split("/")[1] || "webm");
          downloadLink.textContent = "Download recording";
          downloadLink.style.color = "#007bff";
          downloadLink.style.marginLeft = "20px";
          downloadLink.style.fontSize = "14px";
          recordingsRef.current.appendChild(downloadLink);
        }
      }
      if (pauseButtonRef.current) pauseButtonRef.current.style.display = "none";
      if (recordButtonRef.current)
        recordButtonRef.current.textContent = "Record";
      if (uiStyle.type === "button") {
        recordButtonRef.current.style.backgroundColor = "red";
        recordButtonRef.current.style.padding = "10px";
        recordButtonRef.current.style.borderRadius = "6px";
        recordButtonRef.current.style.color = "#FFF";
      } else {
        recordButtonRef.current.textContent = "";
        recordButtonRef.current.style.backgroundColor = "red";
        recordButtonRef.current.style.padding = "10px";
        recordButtonRef.current.style.borderRadius = "10px";
        recordButtonRef.current.style.color = "#FFF";
        recordButtonRef.current.style.color = "#FFF";
      }
    });

    rec.on("start", () => {
      recordingTimerRef.current = 0;
    });

    rec.on("record-progress", (time: number) => {
      const roundedTime = Math.round(time / 1000);
      updateProgress(time);
      if (roundedTime > maxRecordingLength) {
        rec.stopRecording();
      }
    });

    setWaveSurfer(ws);
    setRecord(rec);
  };

  const updateProgress = (time: number) => {
    const formattedTime = [
      Math.floor((time % 3600000) / 60000),
      Math.floor((time % 60000) / 1000),
    ]
      .map((v) => (v < 10 ? "0" + v : v))
      .join(":");
    setTimer(formattedTime);
  };

  const handleStartRecording = () => {
    if (isRecording || isPaused) {
      record.stopRecording();
      setIsRecording(false);
      setIsPaused(false);
      if (recordButtonRef.current)
        recordButtonRef.current.textContent = "Record";
      if (pauseButtonRef.current) pauseButtonRef.current.style.display = "none";
      return;
    }

    if (recordButtonRef.current) recordButtonRef.current.disabled = true;

    const deviceId = micSelectRef.current?.value || "";
    record
      .startRecording({ deviceId })
      .then(() => {
        setIsRecording(true);
        setIsPaused(false);
        if (recordButtonRef.current)
          recordButtonRef.current.textContent = "Stop";
        if (recordButtonRef.current) recordButtonRef.current.disabled = false;
        if (pauseButtonRef.current)
          pauseButtonRef.current.style.display = "inline";
      })
      .catch((error: Error) => {
        console.error("Error starting recording:", error.message);
        if (recordButtonRef.current) recordButtonRef.current.disabled = false;
      });
  };

  const handlePauseRecording = () => {
    if (isPaused) {
      record.resumeRecording();
      setIsPaused(false);
      if (pauseButtonRef.current) pauseButtonRef.current.textContent = "Pause";
      return;
    }

    record.pauseRecording();
    setIsPaused(true);
    if (pauseButtonRef.current) pauseButtonRef.current.textContent = "Resume";
  };

  const handleMicSelectChange = () => {
    createWaveSurfer();
  };

  const handleRecordingTimeout = () => {
    if (isRecording) {
      record.stopRecording();
      setIsRecording(false);
      setIsPaused(false);
      if (recordButtonRef.current)
        recordButtonRef.current.textContent = "Record";
      if (pauseButtonRef.current) pauseButtonRef.current.style.display = "none";
    }
  };
  const handleVolumeChange = (e) => {
    const newVolume = parseFloat(e.target.value);
    setVolume(newVolume);

    if (audioRef.current) {
      audioRef.current.volume = newVolume;
    }
  };
  return (
    <div>
      <div className="grid justify-items-center">
        <h1 className="font-bold mb-5">Audio Recorder</h1>
        <h1 className="text-red-500 font-bold">
          {isRecording
            ? isPaused
              ? "Recording is paused"
              : "Recording"
            : "Click on record to start"}
        </h1>
      </div>

      <div
        id="mic"
        ref={micRef}
        style={{
          border: "1px solid #ddd",
          borderRadius: "4px",
          marginTop: "1rem",
        }}
      ></div>

      <div
        id="recordings"
        ref={recordingsRef}
        style={{ margin: "1rem 0" }}
      ></div>

      <div className="flex items-center gap-10">
        {/* <label style={{ display: "inline-block" }}>
          <input
            type="checkbox"
            onChange={handleScrollingWaveformChange}
            checked={scrollingWaveform}
          />
          Scrolling waveform
        </label> */}

        {uiStyle.pause_recording && (
          <button
            ref={pauseButtonRef}
            onClick={handlePauseRecording}
            style={{ display: "none" }}
          >
            <FaPause fontSize={20} />
          </button>
        )}

        {uiStyle.type === "button" ? (
          <button
            ref={recordButtonRef}
            disabled={!uiStyle.start_recording}
            onClick={handleStartRecording}
            style={{
              backgroundColor: uiStyle.start_recording ? "red" : "#d88e8e",
              padding: 10,
              borderRadius: 6,
              color: "#FFF",
            }}
          >
            Record
          </button>
        ) : (
          <button
            ref={recordButtonRef}
            disabled={!uiStyle.start_recording}
            onClick={handleStartRecording}
          >
            <MdFiberManualRecord
              color={uiStyle.start_recording ? "red" : "#d88e8e"}
              fontSize={25}
            />
          </button>
        )}

        {uiStyle.timer && (
          <div className="flex text-gray-500 gap-1">
            <p id="progress">{timer}</p>
            <span>/</span>
            {formatTime(maxRecordingLength)}
          </div>
        )}

        {uiStyle.volume_control && showVolume && (
         
          <div className="flex">
            <label className="mr-4 items-center">Volume</label>
            <input
         type="range"
         min="0"
         max="1"
         step="0.1"
         value={volume}
         onChange={handleVolumeChange}
            />
          </div>
     
        )}
      </div>

      {/* {selectedControls.max_length && (
        <p>Maximum Recording Length:  seconds</p>
      )} */}
    </div>
  );
};

export default AudioPlugin;
