
const RadioInput = ({isCheckBox, isActive, handleItemClicked, item, children, otherStyle = {}} : 
    {isCheckBox?: boolean; isActive: boolean; handleItemClicked: Function; item: any; children: React.ReactNode; otherStyle?: any}) => {
        

    return(
        <div
            id="radio"
            className="mt-5 mb-5 p-5 flex items-center gap-3"
            style={{
                minHeight: '45px',
                border: '1px solid #eee',
                borderRadius: '6px',
                ...otherStyle
            }}
        >    
            <input
                className="bg-[#DE2128]"
                type={isCheckBox ? "checkbox" :"radio"}
                checked={isActive}
                onChange={(e) => {handleItemClicked(item.id)}}
            />
            {children} 
        </div>
    )
}

export default RadioInput;