import { BlockTitle } from 'components/styled';
import styled, { css } from 'styled-components';

export const QuestionContent = styled.div<{ parentMode?: any }>`
  /* Add styles for the question content */
  ${(props) =>
    props.parentMode &&
    css`
      margin-top: 10px;
      padding: 30px;
      box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 4px;
    `}
`;

export const OptionsList = styled.ul`
  /* Add styles for the options list */
`;

export const Option = styled(BlockTitle)<{
  isCorrect?: boolean;
  showAnswer?: any;
}>`
  background-color: #eee;
  font-weight: 400;
  /* Add styles for each option */
  ${(props) =>
    props.showAnswer &&
    css`
      /* background-color: ${props.isCorrect && '#f0f8ed'}; */
      background-color: ${props.isCorrect && '#7ac491'};
      color: black;
    `}
`;

export const ResponseButton = styled.div<{ type?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  background: var(--red, #de2128);
  height: 37px;
  padding: 10px 10px;
  flex-shrink: 0;
  margin: 10px 0;
  color: white;
  width: fit-content;
  cursor: pointer;
  span {
    margin: 0 5px;
  }
`;

export const FileuploadButton = styled.button<{ type?: string }>`
padding:5px
maring:5px
`;