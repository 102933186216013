import React, { useRef, ChangeEvent, FocusEvent, useState, useCallback, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import { joditConfig } from './JoditConfig';
import { QuestionJoditConfig } from './QuestionJoditConfig';


// Custom Jodit Editor with necessary tools, Math Expression and Upload Image

interface CustomProps {
  label: string,
  value: string,
  onBlur?: (content: string, index?:number) => void;
  onChange?: (content: string, index?:number) => void;
  includeResponseButton?: boolean
  allconfig: boolean
  userconfig?: any,
  className?: string,
  index?:number,
  questionconfig?:boolean
}

const CustomJoditEditor: React.FC<CustomProps> = ({
  label,
  value,
  onBlur,
  onChange,
  includeResponseButton,
  allconfig,
  userconfig,
  className,
  index,
  questionconfig
}) => {

  // const [editorInstance, setEditorInstance] = useState(null);
  const editor = useRef(null);
  const [editorContent, setEditorContent] = useState<string>(value);
  // const [editorInstance, setEditorInstance] = useState(null);

  /* const config = {
     ...joditConfig,
     events: {
       afterInit: (instance) => {
         console.log('Jodit instance initialized:', instance);
         setEditorInstance(instance);
       }
     }
   };*/
  /* const config = {
     ...joditConfig,
     events: {
       afterInit: (instance) => {
         console.log('Jodit instance initialized:', instance);
         setEditorInstance(instance);
       }
     }
   };*/

  /* useEffect(() => {
     const handleSpanClick = (event) => {
       const spanNode = event.target;
       if (spanNode.nodeName === 'SPAN') {
         selectSpan(spanNode);
       }
     };
     console.log(editorInstance);
     if (editorInstance) {
       console.log('Adding click event listener to editorInstance:', editorInstance);
       if (editorInstance.events && typeof editorInstance.events.on === 'function') {
         editorInstance.events.on('click', handleSpanClick);
       } else {
         console.error('editorInstance.events is undefined or does not have an "on" method:', editorInstance.events);
       }
     }
     return () => {
       if (editorInstance) {
         console.log('Removing click event listener from editorInstance:', editorInstance);
         if (editorInstance.events && typeof editorInstance.events.off === 'function') {
           editorInstance.events.off('click', handleSpanClick);
         } else {
           console.error('editorInstance.events is undefined or does not have an "off" method:', editorInstance.events);
         }
       }
     };
   }, [editorInstance]);*/
  /* useEffect(() => {
     const handleSpanClick = (event) => {
       const spanNode = event.target;
       if (spanNode.nodeName === 'SPAN') {
         selectSpan(spanNode);
       }
     };
     console.log(editorInstance);
     if (editorInstance) {
       console.log('Adding click event listener to editorInstance:', editorInstance);
       if (editorInstance.events && typeof editorInstance.events.on === 'function') {
         editorInstance.events.on('click', handleSpanClick);
       } else {
         console.error('editorInstance.events is undefined or does not have an "on" method:', editorInstance.events);
       }
     }
     return () => {
       if (editorInstance) {
         console.log('Removing click event listener from editorInstance:', editorInstance);
         if (editorInstance.events && typeof editorInstance.events.off === 'function') {
           editorInstance.events.off('click', handleSpanClick);
         } else {
           console.error('editorInstance.events is undefined or does not have an "off" method:', editorInstance.events);
         }
       }
     };
   }, [editorInstance]);*/


  /*  const selectSpan = (spanNode) => {
     clearSelection();
     spanNode.style.border = '1px dashed blue'; // Highlight the span
     spanNode.setAttribute('data-selected', 'true');
   };
 
   const clearSelection = () => {
     if (editorInstance) {
       const selectedSpans = editorInstance.editor.querySelectorAll('span[data-selected="true"]');
       selectedSpans.forEach(span => {
         span.style.border = '';
         span.removeAttribute('data-selected');
       });
     }
   }; */
  /*  const selectSpan = (spanNode) => {
     clearSelection();
     spanNode.style.border = '1px dashed blue'; // Highlight the span
     spanNode.setAttribute('data-selected', 'true');
   };
 
   const clearSelection = () => {
     if (editorInstance) {
       const selectedSpans = editorInstance.editor.querySelectorAll('span[data-selected="true"]');
       selectedSpans.forEach(span => {
         span.style.border = '';
         span.removeAttribute('data-selected');
       });
     }
   }; */

  // Initialize Jodit editor on component mount
  // Initialize Jodit editor on component mount
  useEffect(() => {
    if (editor.current) {
      editor.current.value = editorContent;
    }
  }, [editorContent]);

  /*useEffect(() => {
    const addSpanClickListener = () => {
      const editorInstance = editor.current?.editor;

      if (editorInstance) {
        editorInstance.addEventListener('click', handleSpanClick);
      }
    };

    // Try to add event listener after a short delay to ensure the editor is fully initialized
    const timer = setTimeout(addSpanClickListener, 500);

    return () => {
      clearTimeout(timer);
      if (editor.current) {
        const editorInstance = editor.current?.editor;
        console.log(editorInstance , " Editor Instance");
        if (editorInstance) {
          editorInstance.removeEventListener('click', handleSpanClick);
        }
      }
    };
  }, []);*/

  /* const handleSpanClick = (event) => {
     if (event.target.tagName.toLowerCase() === 'span') {
       alert('Span clicked: ' + event.target.innerHTML);
     }
   };
 */

  /* const handleSpanClick = (event) => {
     if (event.target.tagName.toLowerCase() === 'span') {
       alert('Span clicked: ' + event.target.innerHTML);
     }
   };
 */
  const handleContentChange = useCallback((content: string) => {
    // setEditorContent(content);
    // console.log(content, " Editor Content");
    // setEditorContent(content);
    // console.log(content, " Editor Content");
    if (onChange) {
      onChange(content,index);
    }
  }, [onChange,index]);

  // Handle editor blur
  const handleEditorBlur = useCallback((content: string) => {
    //  console.log(content, " Editor Content");
    if (onBlur) 
      onBlur(content, index);
  }, [onBlur,index]);




  //const customconfig = joditConfig(includeResponseButton)
  //const customconfig = joditConfig;

  return (
    <>
      {label &&
      <div
        className="mt-5 mb-5 flex items-center p-5 justify-between"
        style={{
          height: '45px',
          backgroundColor: '#eee',
          borderRadius: '6px',
        }}
      >
        <h2 className="font-semibold">{label}</h2>
      </div>
      }
      <JoditEditor
        className={className ? className : ''}
        config={allconfig  && questionconfig ? QuestionJoditConfig : allconfig ? joditConfig : userconfig}
        // config={allconfig && allconfig? customconfig: userconfig}
        ref={editor}
        value={value}
        onBlur={handleEditorBlur}
        onChange={handleContentChange}

      />
    </>
  );
}

export default CustomJoditEditor;
