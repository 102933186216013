import React from 'react';
import { useDrop } from 'react-dnd';
import { ItemTypes } from 'types';
import DraggableElement from '../DraggableElement/DraggableElement';

interface DroppableAreaProps {
  onDrop: (id: number, label: string) => void;
  id: number;
  droppedItems: string[];
  className?: string; // Allow custom class names
}

const DroppableArea: React.FC<DroppableAreaProps> = ({ onDrop, id, droppedItems, className }) => {
  const [{ isOver }, drop] = useDrop({
    accept: ItemTypes.MATCH,
    drop: (item: { label: string }) => {
      onDrop(id, item.label);
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <div
      ref={drop}
      className={className}
      style={{
        minHeight: '50px',
        minWidth: '100px',
        border: '1px solid #ccc',
        padding: '16px',
        backgroundColor: isOver ? 'lightgreen' : 'white',
        margin: '4px',
      }}
    >
      {droppedItems?.map((item, index) => (
        <DraggableElement key={index} label={item} />
      ))}
    </div>
  );
};

export default DroppableArea;
