import { TOptionItem } from 'types';
import { DnDBetweenLists } from '../partials/DnDBetweenLists/DnDBetweenLists';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';

type SortListBlocProps = {
  sortList: TOptionItem[]; // Renamed to be specific to SortList
  correctAnswers?: TOptionItem[];
  handlePointsChange?: (e: any) => void;
  editMode?: boolean;
  setSortList?: (value: any) => void; // Renamed to be specific to SortList
  handleSortUpdate?: (res: TOptionItem[]) => void;
  showAnswer?: boolean;
  score?: number;
};

export const SortListBloc = ({
  sortList, // Use sortList instead of orderList
  correctAnswers,
  handlePointsChange,
  editMode,
  showAnswer,
  score,
  setSortList,
  handleSortUpdate,
}: SortListBlocProps) => {
  const { t } = useTranslation();
  const [localSortList, setLocalSortList] = useState<TOptionItem[]>(sortList);

  useEffect(() => {
    if (setSortList) {
      setSortList(localSortList);
    }
  }, [localSortList, setSortList]);

  const handleSortUpdateInternal = (res: TOptionItem[]) => {
    setLocalSortList(res);
    if (handleSortUpdate) {
      handleSortUpdate(res);
    }
  };

  return (
    <>
      <div className={'w-full p-2'}>
        <DnDBetweenLists
          correctAnswers={correctAnswers}
          orderList={localSortList} // Use localSortList instead of orderList
          showAnswer={showAnswer}
        />
      </div>

      <div className="flex items-center justify-between mt-4 gap-2">
        {editMode && (
          <div
            className="flex items-center gap-10 p-2"
            style={{
              border: '1px solid #eee',
              borderRadius: '6px',
              width: '300px',
            }}
          >
            <span>{t('points')}</span>
            {score && correctAnswers ? (
              <input
                className="outline-none"
                type="number"
                value={score}
                readOnly
              />
            ) : (
              <input
                className="outline-none"
                placeholder="100"
                type="number"
                value={score}
                onChange={handlePointsChange}
              />
            )}
          </div>
        )}
      </div>
    </>
  );
};

SortListBloc.defaultProps = {
  editMode: false,
};
