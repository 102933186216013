import React, {
  DragEventHandler,
  FocusEventHandler,
  MouseEventHandler,
} from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';

type DragContainerProps = {
  index: number;
  dragItem: any;
  dragOverItem: any;
  onDragEnd: DragEventHandler<HTMLDivElement>;
  children: React.ReactNode;
  OnDeleteElement?: MouseEventHandler<SVGElement>;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onFocus?: FocusEventHandler<HTMLDivElement>;
  onBlur?: FocusEventHandler<HTMLDivElement>;
};

const DragContainer = ({
  index,
  dragItem,
  dragOverItem,
  onDragEnd,
  children,
  OnDeleteElement,
  onClick,
  onFocus,
  onBlur,
}: DragContainerProps) => {
  const listStyle = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["ordlis"] as any)?.more_options?.ui_style
        ?.list_style
  );
  const containerStyle =
  listStyle === 'list'
    ? {}
    : {
        minHeight: '45px',
        border: '1px solid #eee',
        borderRadius: '6px',
        boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      };

  return (
    
    <div
      key={index}
      className="mt-5 mb-5 p-1 px-5 flex items-center gap-2"
      draggable
      onDragStart={(e) => {
        dragItem.current = index;
      }}
      onDragEnter={(e) => {
        dragOverItem.current = index;
      }}
      onDragEnd={onDragEnd}
      onDragOver={(e) => e.preventDefault()}
      style={containerStyle}
      onClick={onClick}
      onFocus={onFocus}
      onBlur={onBlur}
    >
      <GiHamburgerMenu className="text-[#ff6601] cursor-move" />
      {children}
      {OnDeleteElement && (
        <AiOutlineClose
          className="text-[#ff6601] cursor-pointer self-center"
          onClick={OnDeleteElement}
        />
      )}
    </div>
  );
};

export default DragContainer;
