import React, { useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useTranslation } from "react-i18next";
import DOMPurify from "dompurify";
import DropZone from "../partials/DropZone/DropZone";
import Button from "components/Button";
import DraggableElementMatchList from "./DraggableElementMatchList";
import DroppableArea from "../partials/DroppableArea/DroppableArea";
import { TOptionItem } from "types";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";
import DesignElement from "../partials/DesignElement/DesignElement";

type MatchListBlockProps = {
  stimulus: TOptionItem[];
  possibRes: TOptionItem[];
  currentCorrectChoices: any[];
  handleDrop: (index: number, word: string) => void;
  handlePointsChange?: (e: any) => void;
  editMode?: boolean;
  hasGroup?: boolean;
  getId?: any;
  score?: number;
  showAnswer?: boolean;
  correctAnswers?: any[];
  handleDataRest?: any;
  possibOptions: TOptionItem[];
};

export const MatchListBlock = ({
  stimulus,
  possibRes,
  currentCorrectChoices,
  handleDrop,
  handlePointsChange,
  editMode,
  getId,
  hasGroup,
  score,
  showAnswer,
  correctAnswers,
  handleDataRest,
  possibOptions,
}: MatchListBlockProps) => {
  const { t } = useTranslation();
  const [currentChoices, setCurrentChoices] = useState(currentCorrectChoices);
  const [availableItems, setAvailableItems] = useState<string[]>(
    possibRes?.map((item) => item.label)
  );
  const [view, setView] = useState("correct");

  const textRef = useRef<Array<HTMLDivElement | null>>([]);
  const correctTextRef = useRef<Array<HTMLDivElement | null>>([]);

  useEffect(() => {
    stimulus?.forEach((item, i) => {
      if (textRef.current[i]) {
        textRef.current[i].innerHTML = DOMPurify.sanitize(item?.label);
      }
    });
  }, [stimulus]);

  useEffect(() => {
    if (showAnswer) {
      correctAnswers?.forEach((item, i) => {
        if (correctTextRef.current[i]) {
          correctTextRef.current[i].innerHTML = DOMPurify.sanitize(item);
        }
      });
    }
  }, [correctAnswers, showAnswer]);

  useEffect(() => {
    if (view === "alternate") {
      setCurrentChoices(currentCorrectChoices.map(() => ""));
    } else {
      setCurrentChoices(currentCorrectChoices);
    }
  }, [view, currentCorrectChoices]);

  const handleDropInternal = (index, word) => {
    const newChoices = [...currentChoices];

    // Clear the previous slots where this word was dropped
    newChoices.forEach((choice, i) => {
      if (choice === word && i !== index) {
        newChoices[i] = "";
      }
    });

    // Update the new slot with the dropped word
    newChoices[index] = word;
    setCurrentChoices(newChoices);
    handleDrop(index, word);
  };
  const more_options = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["machli"] as any)?.more_options
  );
  const getLabel = (index: number) => {
    let label = "";
    if (more_options?.ui_style
      ?.validation_stem_numeration) {
      switch (more_options?.ui_style
        ?.validation_stem_numeration) {
        case 'numerical':
          label = (index + 1).toString();
          break;
        case 'lower-alpha':
          label = String.fromCharCode(97 + index);
          break;
        case 'upper-alpha':
          label = String.fromCharCode(97 + index).toUpperCase();
          break;
        default:
          break;
      }
    }
    return label;
  };

  const possibility_list_position = useSelector(
    (state: RootState) =>
      (state.question.subQuestions?.["machli"] as any)?.more_options?.ui_style
        ?.possibility_list_position
  );

  const getDroppableArea = () => (
    <DroppableArea
      id={-1}
      onDrop={handleDropInternal}
      droppedItems={availableItems}
      className="flex items-center gap-1 flex-wrap"
    />
  );

  const draggableArea = () => (
    <div className="flex items-center gap-1 flex-wrap">
      {availableItems.map((label, index) => (
        <DraggableElementMatchList key={index} label={label} />
      ))}
    </div>
  );

  const fontSizeMapping = {
    small: "12px",
    medium: "14px",
    large: "17px",
    "x-large": "20px",
    "xx-large": "24px",
  };
  const fontSize = fontSizeMapping[more_options?.ui_style?.fontsize] ;
console.log(fontSize);
  return (
    <DndProvider backend={HTML5Backend}>
      {handleDataRest && <Button title={t("reset")} onClick={handleDataRest} />}
      <div className="mt-4">
        

        {/* Conditional rendering of DroppableArea based on possibility_list_position */}
        {possibility_list_position === "top" && (
          <div className="mb-4">{getDroppableArea()}</div>
        )}

        <div style={{ display: "flex" }}>
          {possibility_list_position === "left" && (
            <div style={{ marginRight: "1rem" }}>{getDroppableArea()}</div>
          )}

          <div
            className="w-full flex items-center justify-center mt-4"
            style={{
              border: "1px solid #ccc",
              borderRadius: "6px",
              width: "100%",
              fontSize: fontSize, // Apply font size dynamically

            }}
          >
            <div className="max-w-[40%]">
              {stimulus?.map((item, i) => (
                <div key={item.id} className="flex items-center">
                  <div className="p-2 border-solid border border-grey-500 my-4 rounded-md min-w-[300px] min-h-[48px] ">
                    <div ref={(el) => (textRef.current[i] = el)} />
                  </div>
                </div>
              ))}
            </div>

            <div>
              {stimulus?.map((item, key) => (
                <div key={item.id} className="flex items-center">
                  <div className="p-2 my-4 h-[48px]">
                    <DesignElement />
                  </div>
                </div>
              ))}
            </div>

            <div className="max-w-[40%]">
              {stimulus?.map((item, key) => (
                <div key={item?.id} className="flex items-center gap-2">
                  {currentChoices && (
                    <DropZone
                      id={key}
                      onDrop={handleDropInternal}
                      filledWord={currentChoices[key]}
                    />
                  )}
                  {showAnswer && correctAnswers && view === "correct" && (
                    <div
                      className={`${
                        correctAnswers[key] === currentChoices[key]
                          ? "border-2 p-1 border-green-500"
                          : "border-2 p-1 border-red-500"
                      }`}
                      style={{ display: 'flex', alignItems: 'center' }}

                    >
                      <span style={{ marginRight: '4px' }}>{getLabel(key)}.</span>

                      <div ref={(el) => (correctTextRef.current[key] = el)} />
                    </div>
                  )}
                </div>
              ))}
            </div>
          </div>

          {possibility_list_position === "right" && (
            <div style={{ marginLeft: "1rem" }}>{getDroppableArea()}</div>
          )}
        </div>

        {possibility_list_position === "bottom" && (
          <div className="mt-4">{getDroppableArea()}</div>
        )}

        {possibility_list_position !== "top" &&
          possibility_list_position !== "left" &&
          possibility_list_position !== "right" &&
          possibility_list_position !== "bottom" &&
          getDroppableArea()}

{editMode && (
              <div
                className="flex items-center gap-10 p-2"
                style={{
                  border: '1px solid #eee',
                  borderRadius: '6px',
                  width: '300px',
                }}
              >
                <span>{t('points')}</span>
                {score && correctAnswers ? (
                  <input
                    className="outline-none"
                    type="number"
                    value={score}
                    readOnly
                  />
                ) : (
                  <input
                    className="outline-none"
                    placeholder="100"
                    type="number"
                    onChange={handlePointsChange}
                    value={score}
                  />
                )}
              </div>
            )}
      </div>
    </DndProvider>
  );
};

MatchListBlock.defaultProps = {
  editMode: false,
};
